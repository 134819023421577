<template>
  <div id="create-Multisale">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl">
            <div class="modal-content bg-white">
              <div class="modal-header p-2">
                <h4 class="modal-title pl-2">Create Multiple Sale</h4>
                <button type="button" class="close pr-4" @click="$emit('close')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body bgcolor">
                <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                  <form @submit.prevent="handleSubmit(validatepassanger)">
                    <b-row class=" pt-3">
                      <b-col md="6" sm="6" lg="4">
                        <iq-card class="pt-2 pb-2" style="min-height: 20em; max-height: 20em; overflow-x: hidden;">
                          <template v-slot:body>
                            <div class="align-items-center justify-content-between">
                              <b-row>
                                <table class="col-md-12">
                                  <div v-for="(clientPassenger, k) in clientPassengerList" :key="k">
                                    <!-- <td> -->
                                    <div class="custom-control custom-checkbox">
                                      <input type="checkbox" class="custom-control-input"
                                        :id="'checkbox' + clientPassenger.id" :value="clientPassenger.id"
                                        v-model="selectedPassengers">
                                      <label class="custom-control-label" :for="'checkbox' + clientPassenger.id"
                                        style="text-transform: uppercase;display: inline-block;margin: 0; padding: 0;">
                                        {{ clientPassenger.last_name }} / {{
                                          clientPassenger.first_name }} {{
                                          clientPassenger.middle_name }}
                                      </label>
                                      <span class="" style="font-size: x-small;display: block;margin: 0; padding: 0;">{{
                                        clientPassenger.gender.charAt(0) }}, {{
                                          clientPassenger.type }}, {{
                                          clientPassenger.date_of_birth | DOB
                                        }}</span>
                                    </div>
                                    <!-- </td> -->
                                  </div>
                                </table>
                              </b-row>
                            </div>
                          </template>
                        </iq-card>
                      </b-col>
                      <b-col md="6" sm="6" lg="8">
                        <iq-card class="pt-2 pb-2" style="min-height: 20em; max-height: 20em; ">
                          <template v-slot:body>
                            <div class="align-items-center justify-content-between">
                              <b-row>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <p class="fontsize-sm m-0"><span class="text-danger">Notice:</span>
                                      Please
                                      refrain
                                      from disclosing any passenger list
                                      information
                                      unless
                                      the customer
                                      explicitly confirms that we have their
                                      consent
                                      to
                                      store
                                      this information in our system.</p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="last_name" rules="required" v-slot="{ errors }">
                                    <label for="last_name" class="form-control-label">Last
                                    </label>
                                    <input type="text" id="last_name" name="last_name"
                                      class="au-input au-input--full form-control inthei"
                                      v-model="clientPassanger.last_name"
                                      :class="errors.length > 0 ? ' is-invalid' : ''" />
                                  </validation-provider>
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="first_name" rules="required" v-slot="{ errors }">
                                    <label for="first_name" class="form-control-label">First</label>
                                    <input type="text" id="first_name" name="first_name"
                                      class="au-input au-input--full form-control inthei"
                                      v-model="clientPassanger.first_name"
                                      :class="errors.length > 0 ? ' is-invalid' : ''" />
                                  </validation-provider>
                                </div>
                                <div class="col-md-4">

                                  <label for="middle_name" class="form-control-label">Middle</label>
                                  <input type="text" id="middle_name" name="middle_name"
                                    class="au-input au-input--full form-control inthei"
                                    v-model="clientPassanger.middle_name" />
                                </div>
                                <div class="col-md-4">
                                  <div class="form-group">
                                    <label for="exampleInputdate">Date of
                                      birth</label>
                                    <!-- <input type="date" class="form-control inthei"
                                                                                id="exampleInputdate" value="2019-12-18"
                                                                                v-model="clientPassanger.date_of_birth"
                                                                                :class="errors.length > 0 ? ' is-invalid' : ''"> -->
                                    <date-picker class=" mb-2" v-model="clientPassanger.date_of_birth" :editable="false"
                                      placeholder="Date of Brith" :disabled-date="isWithinNext14Months"
                                      valueType="YYYY-MM-DD" :format="datePickerFormat"
                                      style="height: 48px; width: 100%; border-radius: 8px;"></date-picker>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="gender" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                      <label for="gender" class=" form-control-label">Gender</label>
                                      <select id="gender" v-model="clientPassanger.gender"
                                        class="au-input au-input--full form-control inthei"
                                        :class="errors.length > 0 ? ' is-invalid' : ''">
                                        <option value="" disabled>Please Select
                                        </option>
                                        <option value="Male">
                                          Male</option>
                                        <option value="Female">
                                          Female</option>
                                      </select>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="type" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                      <label for="type" class=" form-control-label">type</label>
                                      <select id="type" v-model="clientPassanger.type"
                                        class="au-input au-input--full form-control inthei"
                                        :class="errors.length > 0 ? ' is-invalid' : ''">
                                        <option value="" disabled>Please Select
                                        </option>
                                        <option value="ADT">
                                          Adult</option>
                                        <option value="CHD">
                                          Child</option>
                                        <option value="INF">
                                          Infant</option>
                                      </select>
                                    </div>
                                  </validation-provider>
                                </div>
                              </b-row>
                              <b-row>
                                <div class=" col-md-12">
                                  <div class="form-group float-right">
                                    <!-- <button class="btn btn-success ">Add
                                                                            to
                                                                            list</button> -->
                                    <b-button :disabled="loading" type="submit" class="float-right ml-3"
                                      style="width: 8rem;" variant="success" id="submit">
                                      <template v-if="loading">
                                        <i class="fa fa-spinner fa-spin"></i>
                                        Add to
                                        list...
                                      </template>
                                      <template v-else>
                                        <i class="fas fa-save mr-1"></i>Add to
                                        list
                                      </template>
                                    </b-button>
                                  </div>
                                </div>
                              </b-row>
                            </div>
                          </template>
                        </iq-card>
                      </b-col>
                      <b-col md="6" sm="6" lg="12">
                        <!-- <iq-card class="pt-2 pb-2">
                                                    <template v-slot:body>
                                                        <div class="align-items-center justify-content-between">
                                                            <b-row>
                                                                <div class="col-md-3">
                                                                    <div class="form-group">
                                                                        <div id="app">
                                                                            <div id="app">
                                                                                <div class="custom-control custom-checkbox"
                                                                                    v-for="(email, index) in maskedEmailsList"
                                                                                    :key="index">
                                                                                    <input type="checkbox"
                                                                                        class="custom-control-input"
                                                                                        :id="'customCheck' + email.id"
                                                                                        :checked="selectedEmail === email.id"
                                                                                        :disabled="selectedEmail && selectedEmail !== email.value"
                                                                                        @change="updateSelectedEmail(email.value)">
                                                                                    <label class="custom-control-label"
                                                                                        :for="'customCheck' + email.id">{{
                                                                                            email.maskedValue }}</label>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </b-row>
                                                        </div>
                                                    </template>
                                                </iq-card> -->
                      </b-col>

                    </b-row>
                  </form>
                </ValidationObserver>
              </div>
              <div class="modal-footer p-2">
                <button type="button" class="btn btn-success border-success active" @click="addMultipleSale()"
                  :disabled="!hasSelectedPassenger">Create Sale</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<style>
.nowrap {
  white-space: nowrap;
}

.inthei {
  height: 33px;
}

.bgcolor {
  background-color: #d3d3d366;
  padding-top: 0px;
  padding-bottom: 0px;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-body {
  max-height: calc(100vh - 180px);
  overflow-y: auto;
}

.mx-datepicker-popup {
  z-index: 9999
}
</style>

<script>
import {
  _
} from "vue-underscore";
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  data() {
    return {
      sales: {
        pnr: '',
        lead_id: '',
        quotation_id: '',
        sale_notification_to: []
      },
      // quotationList: [],
      selectedPassengers: [],
      selectedEmail: null,
      client_id: null,
      lead: [],
      emails: [],
      quotation_ids: [],
      clientPassengerList: [],
      clientPassanger: {
        first_name: '',
        last_name: '',
        middle_name: '',
        date_of_birth: '',
        gender: '',
      },
      loading: false,
      datePickerFormat: 'MMM/DD/YYYY',
    }

  },
  components: { DatePicker },
  props: {
    showCreateSale: Boolean,
    leadId: {},
    quotationId: [],
    selectedLead: Object,
    selectedClient: Object,
    quotationList: [],
  },
  computed: {
    emailsList() {
      if (this.selectedLead && this.selectedLead.emails && this.selectedLead.emails.length > 0) {
        return this.selectedLead.emails;
      }
      return [];
    },
    maskedEmailsList() {
      return this.emailsList.map(email => {
        const atIndex = email.value.indexOf('@');
        if (atIndex > 2) {
          const maskedValue = email.value.substring(0, 2) + '*'.repeat(atIndex - 2) + email.value.substring(atIndex - 1);
          return { ...email, maskedValue };
        } else {
          return { ...email, maskedValue: email.value };
        }
      });
    },
    hasSelectedPassenger() {
      return this.selectedPassengers.length > 0;
    },
  },
  methods: {
    isWithinNext14Months(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      return date > today
    },
    validatepassanger() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      this.clientPassanger.id > this.add();
    },
    resetForm() {
      this.$refs.observer.reset();
      this.clientPassanger = {
        first_name: '',
        last_name: '',
        middle_name: '',
        date_of_birth: '',
        gender: '',
      }
    },
    updateSelectedEmail(value) {
      if (this.selectedEmail === value) {
        this.selectedEmail = null;
      } else {
        this.selectedEmail = value;
      }
    },
    addMultipleSale() {
      this.sales['lead_id'] = this.leadId;
      this.sales['quotation_ids'] = [];
      this.quotationList.forEach(element => {
        if (element.isSelected) {
          this.sales['quotation_ids'].push(element.id);
        }
      });
      this.sales['client_passenger_ids'] = this.selectedPassengers;
      this.sales['selected_email'] = this.selectedEmail;
      let sale = {
        ...this.sales
      };
      sale.sale_notification_to = this.sales.sale_notification_to.join();
      let _vm = this;
      this.axios
        .post("/sales", sale)
        .then(function (response) {
          window.open('/sales_manage/' + response.data.data.id + '?ref=' + response.data.data.sale_reference_id);
          _vm.$emit('sale-create');
          _vm.$emit('close');
        })
        .catch(function (error) {
        });
    },
    checkEmail(e) {
      if (e.target.checked) {
        this.sales.sale_notification_to.push(e.target.value)
      } else {
        let index = this.sales.sale_notification_to.indexOf(e.target.value)

        this.sales.sale_notification_to.splice(index, 1)
      }
    },
    getLead(id) {
      let _vm = this;
      this.axios
        .get("/leads/" + id)
        .then(function (response) {
          _vm.showLoader = false;
          _vm.lead = JSON.parse(atob(response.data.data));
        })
        .catch(function (error) {
        });
    },
    add() {
      let _vm = this;
      const dataToSend = {
        ...this.clientPassanger,
        client_id: this.client_id
      };
      _vm.axios
        .post("/client-passengers", dataToSend)
        .then(function (response) {

          _vm.getClientPassenger();
          _vm.resetForm();
        })
        .catch(function () { });
    },
    getClientPassenger() {
      let _vm = this;
      const selectedClientId = this.selectedLead.client_id;
      _vm.axios
        .get(`/client-passengers?client_id=${selectedClientId}`)
        .then(response => {
          this.clientPassengerList = response.data.data;
        })
        .catch(error => {
        });
    }
  },
  mounted() {
    //  this.getLead(this.leadId);


    this.getClientPassenger();
    let _vm = this;
    _vm.client_id = _vm.selectedLead.client_id;
    if (window.location.hash && window.location.hash.length > 0) {
      let hash = window.location.hash.split('#')[1];

    }

  }
}
</script>
