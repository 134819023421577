import { _ } from "vue-underscore";
import store from "@/store/index.js";

export default {
  data() {
    return {
      // clientList: [],
      marketingData: null,
      showLoader: false,
      marketingclientData: {}, // Initialize as an empty object
      showLoader: false,
    };
  },
  props: {
    selectedLead: Object,
  },

  computed: {},
  methods: {
    // getClientList() {
    //   let _vm = this;
    //   _vm.showLoader = true;
    //   this.axios
    //     .get("/leads-by-client/" + this.$route.params.id)
    //     .then(function (response) {
    //       _vm.clientList = JSON.parse(atob(response.data.data));
    //       _vm.showLoader = false;
    //     })
    //     .catch(function () {});
    // },
    getMarketingList() {
      let _vm = this;
      _vm.showLoader = true;
      store.state.isLoaderShow = true;
      this.axios
        .get("/lead-marketing-utm/" + this.$route.params.id)
        .then(function (response) {
          _vm.marketingData = response.data.data;
          store.state.isLoaderShow = false;
          _vm.showLoader = false;
        })
        .catch(function () {});
    },
    getMarketingClientList() {
      let _vm = this;
      store.state.isLoaderShow = true;
      _vm.showLoader = true;
      this.axios
        .get("/lead-marketing-headers/" + this.$route.params.id)
        .then(function (response) {
          _vm.marketingclientData = response.data.data;
          store.state.isLoaderShow = false;
          _vm.showLoader = false;
        })
        .catch(function () {});
    },
    // createNewLead() {
    //   this.$router.push({
    //     path: "/lead/add-lead",
    //     query: { email: this.clientList[0].client.client_emails[0].value },
    //   });
    // },
    openUrlInNewTab(url) {
      window.open(url, "_blank");
    },
  },
  mounted() {
    let _vm = this;
    _vm.getMarketingList();
    _vm.getMarketingClientList();
    // Vue.filter("formatdate", function (value) {
    //   if (!value) return "";
    //   return moment(String(value)).format("MMM-DD-YY");
    // });
  },
};
