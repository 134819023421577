import store from "@/store/index.js";
import intlTelInput from "intl-tel-input";
import "intl-tel-input/build/css/intlTelInput.css";
import Multiselect from "vue-multiselect";
import { BButton, BPopover } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import Vue from "vue";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";

import { _ } from "vue-underscore";
export default {
  data: () => ({
    isEmailSearchRunning: false,
    lead: {
      id: 0,
      rate_request: "",
      trips: [],
      client: {
        client_emails: [
          {
            comment: null,
            preferred: false,
            value: "",
          },
        ],
        client_phones: [
          {
            prefix: null,
            value: "",
            comment: null,
            preferred: false,
          },
        ],
      },
      preferences: {
        purpose_of_travel: "",
        market_price: null,
        client_budget: null,
        airline_preference: null,
        number_of_stops: null,
        need_hotel: null,
        client_values: {
          urgency: false,
          flat_bed: false,
          flexible_travel_dates: false,
          flight_convenience: false,
          airline_choice: false,
          book_today_for_better_price: false,
        },
      },
    },
    answer: "",
    searchEmail: "",
    fromDest: {},
    toDest: {},
    countryIti: null,
    error: "",
    backUrl: "/lead",
    disabled: false,
    companyList: [],
    requestRunning: false,
    airlinesList: [],
    selectedAirline: [],
    leadId: null,
    selectedPhoneIndex: null,
    selectedPhoneorEmailid: null,
    comment: "",
    modal_phone_comment: false,
    selectedPhoneId: null,
    selectedType: "",
    modal_email_comment: false,
    deletion_request_model: false,
    req_del_comment: "",
    selectedEmailId: null,
    value: "",
    currentPhone: "",
    deletion_request_comment: "",
    currentEmail: "",
    currentEmailIcon: false,
    currentPhoneIcon: false,
    showFullEmail: false,
    showFullPhone: false,
    iconClass: "fa-solid fa-eye",
    iconClass1: "fa-solid fa-eye",
    maxCharacters: 250,
    remainingCharacters: 250,
    emailComment: "",
    maxCharactersPhone: 250,
    remainingCharactersPhone: 250,
    phoneComment: null,
    datePickerFormat: "MMM-DD-YYYY",
    commentInput: "",
    predefinedOptions: [
      { value: "Bing", label: "Bing" },
      { value: "Email", label: "Email" },
      { value: "Google", label: "Google" },
      { value: "Kayak", label: "Kayak" },
      { value: "Return/Referral", label: "Return/Referral" },
      { value: "Skyscanner", label: "Skyscanner" },
      { value: "SMS", label: "SMS" },
      { value: "Yahoo", label: "Yahoo" },
      { value: "Other", label: "Other" },
    ],
    unsavedChanges: false,
    isMounted: false,
    originalReqDelComment: "",
  }),
  components: {
    Multiselect,
    BButton,
    BPopover,
    DatePicker,
  },
  watch: {
    "lead.trips": {
      handler(newVal, oldVal) {
        newVal.forEach((trip, index) => {
          if (trip.adults <= 0) {
            this.$set(this.lead.trips, index, { ...trip, adults: 1 });
          }
        });
      },
      deep: true,
    },
  },
  computed: {
    hasClientDeleteContactPermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "client-delete-contacts";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
    // user() {
    //   return this.$store.state.userDetail;
    // },
    // userType() {
    //   return this.user && this.user.id > 0 ? this.user.roles[0].name : "";
    // },
  },
  methods: {
    handleInputChange() {
      this.unsavedChanges = true;
    },
    formatPhoneNumber(index) {
      let phoneNumber = this.lead.client.client_phones[index].currentPhone;
      phoneNumber = phoneNumber.replace(/\D/g, "");
      this.lead.client.client_phones[index].currentPhone = phoneNumber;
      this.unsavedChanges = true;
    },
    isWithinNext14Months(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      const next14Months = new Date(
        today.getFullYear(),
        today.getMonth() + 14,
        today.getDate()
      );
      return date < today || date >= next14Months;
    },
    notBeforeToday(date) {
      return date < new Date(new Date().setHours(0, 0, 0, 0));
    },
    notBeforeTodayTwelveOClock(date) {
      return date < new Date(new Date().setHours(12, 0, 0, 0));
    },
    isValueInOptions(value) {
      return this.predefinedOptions.some((option) => option.value === value);
    },
    updateRemainingCharactersPhone() {
      if (this.phoneComment.length > this.maxCharactersPhone) {
        this.phoneComment = this.phoneComment.slice(0, this.maxCharactersPhone);
      }
      this.remainingCharactersPhone =
        this.maxCharactersPhone - this.phoneComment.length;
    },
    updateRemainingCharacters() {
      if (this.emailComment.length > this.maxCharacters) {
        this.emailComment = this.emailComment.slice(0, this.maxCharacters);
      }
      this.remainingCharacters = this.maxCharacters - this.emailComment.length;
    },
    handleInputClickfromDest(index) {
      const inputElement = document.getElementById(`fromDest_${index}`);
      if (inputElement) {
        inputElement.select();
      }
    },
    handleInputClicktoDest(index) {
      const inputElement = document.getElementById(`toDest_${index}`);
      if (inputElement) {
        inputElement.select();
      }
    },
    handleInputClickAdult(index) {
      const inputElement = document.getElementById(`adult_${index}`);
      if (inputElement) {
        inputElement.select();
      }
    },
    handleInputClickChild(index) {
      const inputElement = document.getElementById(`child_${index}`);
      if (inputElement) {
        inputElement.select();
      }
    },
    handleInputClickInfant(index) {
      const inputElement = document.getElementById(`infant_${index}`);
      if (inputElement) {
        inputElement.select();
      }
    },
    selectInputText(inputElement) {
      inputElement.select();
    },
    emailsShowHide(index) {
      const email = this.lead.client.client_emails[index].value;
      const [username, domain] = email.split("@");
      const obfuscatedUsername =
        username.slice(0, 2) + "*".repeat(username.length - 2);
      this.lead.client.client_emails[index].currentEmail = email;
      this.lead.client.client_emails[index].currentEmail =
        obfuscatedUsername + "@" + domain;
      this.lead.client.client_emails[index].iconClass = "fa-solid fa-eye";
    },

    toggleEmailVisibility(index) {
      const _vm = this;
      const email = _vm.lead.client.client_emails[index];
      if (!email.currentEmailIcon) {
        _vm.$set(email, "currentEmailIcon", true);
        if (email.iconClass === "fa-solid fa-eye") {
          _vm.axios
            .get("/lead-view-count")
            .then((response) => {
              if (response.data.data === true) {
                _vm.$set(email, "iconClass", "fa-solid fa-eye-slash");
                email.currentEmail = email.value;
              } else {
                _vm.emailsShowHide(index);
              }
            })
            .finally(() => {
              _vm.$set(email, "currentEmailIcon", false);
            });
        } else {
          _vm.$set(email, "iconClass", "fa-solid fa-eye");
          _vm.$set(email, "currentEmailIcon", false);
          this.emailsShowHide(index);
        }
      }
    },

    obfuscatePhoneNumber(index) {
      const phone = this.lead.client.client_phones[index].value;
      const obfuscatedPhone =
        phone.slice(0, 2) + "*".repeat(phone.length - 4) + phone.slice(-3);
      this.lead.client.client_phones[index].currentPhone = phone;
      this.lead.client.client_phones[index].currentPhone = obfuscatedPhone;
      this.lead.client.client_phones[index].iconClass1 = "fa-solid fa-eye";
    },

    togglePhoneVisibility(index) {
      const _vm = this;
      const phone = _vm.lead.client.client_phones[index];
      if (!phone.currentPhoneIcon) {
        _vm.$set(phone, "currentPhoneIcon", true);
        if (phone.iconClass1 === "fa-solid fa-eye") {
          _vm.axios
            .get("/lead-view-count")
            .then((response) => {
              if (response.data.data === true) {
                _vm.$set(phone, "iconClass1", "fa-solid fa-eye-slash");
                phone.currentPhone = phone.value;
              } else {
                _vm.obfuscatePhoneNumber(index);
              }
            })
            .finally(() => {
              _vm.$set(phone, "currentPhoneIcon", false);
            });
        } else {
          _vm.$set(phone, "iconClass1", "fa-solid fa-eye");
          _vm.$set(phone, "currentPhoneIcon", false);
          _vm.obfuscatePhoneNumber(index);
        }
      }
    },

    onInputChange(fieldName) {
      if (this.lead[fieldName] !== this.originalValues[fieldName]) {
      } else {
      }
    },
    openModal(index) {
      this.modal_phone_comment = true;
      this.selectedPhoneId = this.lead.client.client_phones[index].id;
      this.phoneComment = this.lead.client.client_phones[index].comment;
    },
    openDeletionRequestCommentModal(type, index) {
      this.deletion_request_model = true;
      this.selectedType = type;

      if (type == "phone") {
        this.selectedPhoneorEmailid = this.lead.client.client_phones[index].id;
        this.originalReqDelComment = this.lead.client.client_phones[index].deletion_request_comment;
      }
      if (type == "email") {
        this.selectedPhoneorEmailid = this.lead.client.client_emails[index].id;
        this.originalReqDelComment = this.lead.client.client_emails[index].deletion_request_comment;
      }
    },
    requestDelete(type, selectedPhoneId) {
      let _vm = this;
      const payload = {
        type: type,
        lead_id: this.lead.id,
        contact_id: selectedPhoneId,
        deletion_request_comment: this.req_del_comment,
      };
      _vm.axios
        .post('request-deletion-client-contact', payload)
        .then(function (response) {
          _vm.getDetail();
          _vm.deletion_request_model = false;
        })
        .catch(function () { });
    },
    approveDelete(type, selectedPhoneId) {
      let _vm = this;
      const payload = {
        type: type,
        lead_id: this.lead.id,
        contact_id: selectedPhoneId,
      };
      _vm.axios
        .post('process-deletion-client-contact', payload)
        .then(function (response) {
          _vm.getDetail();
          _vm.deletion_request_model = false;
        })
        .catch(function () { });
    },
    rejectDelete(type, selectedPhoneId) {
      let _vm = this;
      const payload = {
        type: type,
        lead_id: this.lead.id,
        contact_id: selectedPhoneId,
        rejection_comment: this.req_del_comment,
      };
      _vm.axios
        .post('reject-deletion-client-contact', payload)
        .then(function (response) {
          _vm.getDetail();
          _vm.deletion_request_model = false;
        })
        .catch(function () { });
    },
    closeDeletionRequestCommentModal(index) {
      this.deletion_request_model = false;
    },
    closeModal(index) {
      this.modal_phone_comment = false;
      const phoneToUpdate = this.lead.client.client_phones.find(
        (phone) => phone.id === index
      );
      if (phoneToUpdate) {
        phoneToUpdate.comment = this.phoneComment;
        this.handleInputChange();
      }
    },
    openEmailModal(index) {
      this.selectedEmailId = this.lead.client.client_emails[index].id;
      this.modal_email_comment = true;
      this.emailComment = this.lead.client.client_emails[index].comment;
    },
    closeEmailModal(index) {
      this.modal_email_comment = false;
      const emailToUpdate = this.lead.client.client_emails.find(
        (email) => email.id === index
      );
      if (emailToUpdate) {
        emailToUpdate.comment = this.emailComment;
        this.handleInputChange();
      }
    },
    getCompany() {
      let _vm = this;
      this.axios
        .get("company-list")
        .then(function (response) {
          _vm.companyList = response.data.data;
        })
        .catch(function () { });
    },
    addNewPhone() {
      if (!this.lead || !this.lead.client.client_phones) {
        this.lead = {
          phones: [],
        };
      }
      this.lead.client.client_phones.push({
        contact_type: "Work",
      });
      this.$nextTick(() => {
        const index = this.lead.client.client_phones.length - 1;
        const input = document.querySelector("#contact_number_" + index);
        this.lead.client.client_phones[index]["countryIti"] = intlTelInput(
          input,
          {
            separateDialCode: true,
          }
        );
      });
    },
    resetModal() {
      this.lead.client.client_phones[0].phoneComment = "";
    },
    savePhoneComment(comment) {
      if (this.selectedPhoneIndex !== null) {
        this.lead.client.client_phones[this.selectedPhoneIndex].phoneComment =
          comment;
      }
      $("#exampleModal1").modal("hide");
    },
    searchAirport(search, index) {
      if (search.length) {
        let _vm = this;
        this.axios
          .get("search-airport?search_key=" + search)
          .then(function (response) {
            _vm.lead.trips[index].fromAirportList = response.data.data;
          });
      }
    },
    validatePage() {
      let _vm = this;
      let lead_trips = [];
      const airline = [];
      const trip = this.lead.trips;
      let hasValidationError = false;
      this.selectedAirline.forEach((element) => {
        airline.push(element.code);
      });
      for (let i = 0; i < _vm.lead.trips.length; i++) {
        const typeFrom = "fromDest";
        const typeTo = "toDest";

        if (!trip[i] || !trip[i].fromDestSelectedLocation) {
          hasValidationError = true;
          this.$nextTick(() => {
            $(`#${typeFrom}_${i}`).addClass("is-invalid");
            $(`#${typeFrom}SearchList_${i}`).addClass("show");
          });
        } else {
          this.$nextTick(() => {
            $(`#${typeFrom}_${i}`).removeClass("is-invalid");
            $(`#${typeFrom}SearchList_${i}`).removeClass("show");
          });
        }

        if (!trip[i] || !trip[i].toDestSelectedLocation) {
          hasValidationError = true;
          this.$nextTick(() => {
            $(`#${typeTo}_${i}`).addClass("is-invalid");
            $(`#${typeTo}SearchList_${i}`).addClass("show");
          });
        } else {
          this.$nextTick(() => {
            $(`#${typeTo}_${i}`).removeClass("is-invalid");
            $(`#${typeTo}SearchList_${i}`).removeClass("show");
          });
        }
      }
      if (hasValidationError) {
        return;
      }
      for (let i = 0; i < _vm.lead.trips.length; i++) {
        const type = !trip.fromDestSelectedLocation ? "fromDest" : "toDest";
        const listIndex = i;
        $(`#${type}_${listIndex}`).removeClass("is-invalid");
        $(`#${type}SearchList_${listIndex}`).removeClass("show");
        $(`#${type}_${listIndex}`).removeClass("is-invalid");
        $(`#${type}SearchList_${listIndex}`).removeClass("show");
      }
      if (this.commentInput.trim() !== "") {
        this.lead.lead_feedback_comment.push({
          id: 0,
          is_feedback: 0,
          comment: this.commentInput,
        });
        this.commentInput = "";
      }
      _vm.requestRunning = true;
      function transformTrip(trip) {
        const from_airport_code =
          trip.fromDestSelectedLocation.airport_code ||
          trip.fromDestSelectedLocation.code;
        const to_airport_code =
          trip.toDestSelectedLocation.airport_code ||
          trip.toDestSelectedLocation.code;

        const fromLocation = {
          country: trip.from?.country || trip.fromDestSelectedLocation.country,
          city: trip.from?.city || trip.fromDestSelectedLocation.city,
          code: trip.from?.code || from_airport_code,
        };

        const toLocation = {
          country: trip.to?.country || trip.toDestSelectedLocation.country,
          city: trip.to?.city || trip.toDestSelectedLocation.city,
          code: trip.to?.code || to_airport_code,
        };

        return {
          adults: trip.adults,
          cabin: trip.cabin,
          children: trip.children,
          date: trip.date,
          from: fromLocation,
          to: toLocation,
          infants: trip.infants,
        };
      }

      if (_vm.lead.trips && _vm.lead.trips.length > 0) {
        lead_trips = _vm.lead.trips.map(transformTrip);
      }
      for (
        let index = 0;
        index < _vm.lead.client.client_emails.length;
        index++
      ) {
        if (!_vm.lead.client.client_emails[index].id > 0) {
          _vm.lead.client.client_emails[index].value =
            _vm.lead.client.client_emails[index].currentEmail;
        }
      }
      for (
        let index = 0;
        index < _vm.lead.client.client_phones.length;
        index++
      ) {
        if (!_vm.lead.client.client_phones[index].id > 0) {
          _vm.lead.client.client_phones[index].value =
            _vm.lead.client.client_phones[index].currentPhone;
        }
      }
      _vm.lead["trips"] = lead_trips;
      for (
        let index = 0;
        index < _vm.lead.client.client_phones.length;
        index++
      ) {
        _vm.lead.client.client_phones[index]["prefix"] =
          "+" +
          _vm.lead.client.client_phones[index][
            "countryIti"
          ].getSelectedCountryData().dialCode;
      }
      const preferencesData = {
        purpose_of_travel: this.lead.preferences.purpose_of_travel,
        market_price: this.lead.preferences.market_price,
        client_budget: this.lead.preferences.client_budget,
        airline_preference: airline,
        number_of_stops: this.lead.preferences.number_of_stops,
        need_hotel: this.lead.preferences.need_hotel,
        client_values: {},
      };
      const checkboxValues = [
        "book_today_for_better_price",
        "airline_choice",
        "flexible_travel_dates",
        "flight_convenience",
        "urgency",
        "flat_bed",
      ];
      checkboxValues.forEach((value) => {
        preferencesData.client_values[value] =
          this.lead.preferences.client_values[value] || false;
      });

      _vm.lead["preferences"] = preferencesData;

      const requestData = {
        ...this.lead,
        trips: lead_trips,
        _method: "PATCH",
      };
      this.axios
        .post("/leads/" + this.lead.id, requestData)
        .then(function (response) {
          if (_vm.$route.params.id > 0) {
            _vm.getDetail();
          } else {
            window.location.href =
              "/lead/" + JSON.parse(atob(response.data.data)).id;
          }
        })
        .catch(function (error) {
          _vm.requestRunning = false;
        })
        .finally(function () {
          _vm.unsavedChanges = false;
        });
    },
    getDetail() {
      let _vm = this;
      store.state.isLoaderShow = true;
      this.axios
        .get("/leads/" + this.$route.params.id)
        .then(function (response) {
          let data = JSON.parse(atob(response.data.data));
          store.state.isLoaderShow = false;
          _vm.lead = data;

          if (data.client.client_phones.length == 0) {
            _vm.lead.client.client_phones = [
              { prefix: "", value: "", comment: null },
            ];
          }
          if (data.client.client_emails.length == 0) {
            _vm.lead.client.client_emails = [{ value: "", comment: null }];
          }
          const emails = [];
          if (
            data.client.client_emails &&
            Array.isArray(data.client.client_emails)
          ) {
            data.client.client_emails.forEach((element, index) => {
              emails.push({
                ...element,
                iconClass: "fa-solid fa-eye",
                currentEmail: element.value
                  ? `${element.value.slice(0, 2)}***@${element.value.slice(
                    element.value.indexOf("@") + 1
                  )}`
                  : "",
                showFullEmail: false,
              });
            });
          } else {
            emails.push({
              iconClass: "fa-solid fa-eye",
              id: 0,
              lead_id: _vm.lead.id,
              comments: "",
              prefix: null,
              value: "",
              currentEmail: "",
              showFullEmail: false,
            });
          }
          _vm.lead.client.client_emails = emails;

          const phones = [];
          if (
            data.client.client_phones &&
            Array.isArray(data.client.client_phones)
          ) {
            data.client.client_phones.forEach((element, index) => {
              phones.push({
                ...element,
                iconClass1: "fa-solid fa-eye",
                currentPhone: element.value
                  ? `(${element.value.slice(0, 3)}) ${element.value.slice(
                    3,
                    6
                  )}-${element.value.slice(6)}`
                  : "",
                showFullPhone: false,
              });
            });
          } else {
            phones.push({
              iconClass1: "fa-solid fa-eye",
              prefix: "",
              value: "",
              comment: null,
              currentPhone: "",
              showFullPhone: false,
            });
          }
          _vm.lead.client.client_phones = phones;
          let defaultPhonesArray = [{ preferred: "true", comments: "" }];
          _vm.$set(_vm.lead, "phones", defaultPhonesArray);

          _vm.$nextTick(() => {
            if (
              _vm.lead.client.client_phones &&
              Array.isArray(_vm.lead.client.client_phones)
            ) {
              _vm.lead.client.client_phones.forEach((element, index) => {
                _vm.obfuscatePhoneNumber(index);
              });
            }

            if (
              _vm.lead.client.client_emails &&
              Array.isArray(_vm.lead.client.client_emails)
            ) {
              _vm.lead.client.client_emails.forEach((element, index) => {
                _vm.emailsShowHide(index);
              });
            }
          });

          _vm.$emit("updateLeadDetail", _vm.lead);
          _vm.$store.state.leadId = _vm.lead.id;

          if (_vm.lead.trips && _vm.lead.trips.length > 0) {
            _vm.$nextTick(() => {
              for (let index = 0; index < _vm.lead.trips.length; index++) {
                const element = _vm.lead.trips[index];
                _vm.loadAirportLookup(index, "fromDest");
                _vm.loadAirportLookup(index, "toDest");

                $("#departDate_" + index).val(element.from_date);
                $("#fromDest_" + index).val(
                  element.from.code + " - " + element.from.city
                );
                $("#toDest_" + index).val(
                  element.to.code + " - " + element.to.city
                );
                _vm.lead.trips[index]["toDest"] = {
                  country: element.to.country,
                  code: element.to.code,
                  city: element.to.city,
                  // city_code: "",
                  airport: element.to_airport,
                  airport_code: element.to_airport_code,
                };
                _vm.lead.trips[index]["fromDest"] = {
                  country: element.from.country,
                  code: element.from.code,
                  city: element.from.city,
                  // city_code: "",
                  airport: element.from_airport,
                  airport_code: element.from_airport_code,
                };
                _vm.lead.trips[index]["fromDestSelectedLocation"] =
                  _vm.lead.trips[index]["fromDest"];
                _vm.lead.trips[index]["toDestSelectedLocation"] =
                  _vm.lead.trips[index]["toDest"];
              }
            });
          } else {
            _vm.lead.trips = [];
            _vm.addNewSegment();
          }
          _vm.$nextTick(() => {
            for (
              let index = 0;
              index <= _vm.lead.client.client_phones.length;
              index++
            ) {
              const input = document.querySelector("#contact_number_" + index);
              _vm.lead.client.client_phones[index]["countryIti"] = intlTelInput(
                input,
                {
                  separateDialCode: true,
                }
              );
              _vm.lead.client.client_phones[index]["countryIti"].setNumber(
                _vm.lead.client.client_phones[index]["prefix"] +
                _vm.lead.client.client_phones[index]["value"]
              );
            }
            _vm.requestRunning = false;
          });
          const airlinePreferenceSet = new Set(
            _vm.lead.preferences.airline_preference
          );
          _vm.selectedAirline = _vm.airlinesList
            .filter((element) => airlinePreferenceSet.has(element.code))
            .map(({ name, code }) => ({ name, code }));

          _vm.originalLead = JSON.parse(JSON.stringify(_vm.lead));
        })
        .catch(function (error) { });
    },
    setLeadSource(e) {
      let source = e.target.value;
      if (source === "Return" || source === "Referral") {
        $("#lead_source").val("Return/Referral");
        this.lead.lead_source = $("#lead_source").val();
        $("#lead_source").attr("disabled", "disabled");
      } else {
        $("#lead_source").attr("disabled", false);
      }
    },
    removePhone(id, index) {
      const phone = this.lead.client.client_phones[index];
      if (id || !phone.id) {
        this.lead.client.client_phones.splice(index, 1);
      }
    },
    setPreferredPhone(index) {
      this.lead.client.client_phones.forEach((phone, i) => {
        phone.preferred = index === i ? 1 : 0;
      });
      this.handleInputChange();
    },
    setPreferredEmail(index) {
      this.lead.client.client_emails.forEach((email, i) => {
        email.preferred = index === i ? 1 : 0;
      });
      this.handleInputChange();
    },

    removeEmail(id, index) {
      const emails = this.lead.client.client_emails[index];
      if (id || !emails.id) {
        this.lead.client.client_emails.splice(index, 1);
      }
    },
    deleteRecord() {
      let _vm = this;
      this.$dialog
        .confirm("Are you sure you want to delete this record?")
        .then(function () {
          _vm.axios
            .delete("/leads/" + _vm.lead.id)
            .then(function () {
              _vm.$router.push(_vm.backUrl);
            })
            .catch(function (error) { });
        })
        .catch(function (error) { });
    },
    removeSegment(index) {
      let _vm = this;
      _vm.$dialog
        .confirm("Are you sure you want to delete record " + (index + 1) + "?")
        .then(() => {
          this.lead.trips.splice(index, 1);
          if (_vm.lead.trips && _vm.lead.trips.length > 0) {
            _vm.$nextTick(() => {
              for (let index = 0; index < _vm.lead.trips.length; index++) {
                const element = _vm.lead.trips[index];
                _vm.loadAirportLookup(index, "fromDest");
                _vm.loadAirportLookup(index, "toDest");

                $("#departDate_" + index).val(element.from_date);
                $("#fromDest_" + index).val(
                  element.from.code + " - " + element.from.city
                );
                $("#toDest_" + index).val(
                  element.to.code + " - " + element.to.city
                );
                _vm.lead.trips[index]["toDest"] = {
                  country: element.to.country,
                  code: element.to.code,
                  city: element.to.city,
                  airport: element.to_airport,
                  airport_code: element.to_airport_code,
                };
                _vm.lead.trips[index]["fromDest"] = {
                  country: element.from.country,
                  code: element.from.code,
                  city: element.from.city,
                  // city_code: "",
                  airport: element.from_airport,
                  airport_code: element.from_airport_code,
                };
                _vm.lead.trips[index]["fromDestSelectedLocation"] =
                  _vm.lead.trips[index]["fromDest"];
                _vm.lead.trips[index]["toDestSelectedLocation"] =
                  _vm.lead.trips[index]["toDest"];
              }
            });
          }
          this.handleInputChange();
        })
        .catch((error) => { });
    },

    addNewSegment() {
      let lastElement = this.lead.trips[this.lead.trips.length - 1];
      this.lead.trips.push({
        adults: lastElement ? lastElement.adults : "",
        children: lastElement ? lastElement.children : 0,
        infants: lastElement ? lastElement.infants : 0,
        cabin: lastElement ? lastElement.cabin : "",
        date: lastElement ? lastElement.date : "",
        lead_id: this.$route.params.id,
        fromDest: lastElement ? lastElement["toDest"] : "",
        toDest: lastElement ? lastElement["fromDest"] : "",
        fromDestSelectedLocation: lastElement ? lastElement["toDest"] : "",
        toDestSelectedLocation: lastElement ? lastElement["fromDest"] : "",
      });
      let _vm = this;
      this.$nextTick(() => {
        let index = 0;
        if (_vm.lead.trips.length > 0) {
          index = _vm.lead.trips.length - 1;
          let element = _vm.lead.trips[index];
          if (element.fromDest != "") {
            $("#fromDest_" + index).val(
              element.fromDest.code + " - " + element.fromDest.city
            );
          }
          if (element.toDest != "") {
            $("#toDest_" + index).val(
              element.toDest.code + " - " + element.toDest.city
            );
          }
        }

        _vm.loadAirportLookup(index, "fromDest");
        _vm.loadAirportLookup(index, "toDest");
        let minDate = new Date();
        if (this.lead.trips.length > 1) {
          minDate = new Date(
            $("#departDate_" + (this.lead.trips.length - 2)).val()
          );
          if (!(minDate instanceof Date && !isNaN(minDate))) {
            minDate = new Date();
          }
        }
      });
      this.canRemoveLastSegment = this.lead.trips.length > 1;
      this.handleInputChange();
    },
    searchClientByEmail(email, index) {
      let _vm = this;
      if (this.searchEmail == email) {
        return;
      }
      store.state.isLoaderShow = true;
      this.searchEmail = email;
      this.isEmailSearchRunning = true;
      this.axios
        .get("/search-client-by-email?email=" + email)
        .then(function (response) {
          let data = JSON.parse(atob(response.data.data));
          store.state.isLoaderShow = false;
          if (data.client && data.client.id > 0) {
            if (_vm.lead.client.cwe == 0) {
              _vm.$dialog
                .alert(
                  "Email already used in other client please use another email."
                )
                .then(function () {
                  _vm.lead.client.client_emails[index].currentEmail = "";
                  _vm.lead.client.client_emails[index].value = "";
                  _vm.searchEmail = "";
                  _vm.isEmailSearchRunning = false;
                })
                .catch(function (error) {
                  _vm.isEmailSearchRunning = false;
                });
              return;
            } else if (_vm.lead.client.cwe == 1) {
              let leadId = _vm.lead.id;
              let clientId = data.client.id;
              _vm.$dialog
                .alert("You have to merge this client to this lead.")
                .then(() => {
                  store.state.isLoaderShow = true;
                  _vm.axios
                    .get(`cwe-merge/${leadId}/${clientId}`)
                    .then(() => {
                      store.state.isLoaderShow = false;
                      location.reload();
                    })
                    .catch(() => {
                      _vm.lead.client.client_emails[index].currentEmail = "";
                      _vm.lead.client.client_emails[index].value = "";
                      _vm.searchEmail = "";
                      store.state.isLoaderShow = false;
                      _vm.isEmailSearchRunning = false;
                    });
                })
                .catch(function (error) {
                  _vm.isEmailSearchRunning = false;
                });
              return;
            }
          }
          _vm.isEmailSearchRunning = false;
          _vm.handleInputChange();
        })
        .catch(function (error) {
          _vm.isEmailSearchRunning = false;
        });
    },

    getQueryString() {
      let queryString = "?search=";
      return queryString;
    },
    loadAirport: function (id) {
      var self = this;
      $("#" + id)
        .autocomplete({
          source: function (request, response) {
            $.get(
              process.env.VUE_APP_API_URL +
              "search-airport?search_key=" +
              request.term,
              {},
              function (data) {
                response(data.data);
              }
            );
          },
          minLength: 3,
          select: function (event, ui) {
            event.preventDefault();
            var selectedObj = ui.item;
            let index = id.split("_")[1];
            $("#" + id).val(
              selectedObj.airport_code + " - " + selectedObj.city
            );
            $("#" + id).removeClass("has-error");
            if (id.indexOf("fromDest") > -1) {
              self.$set(self.lead.trips[index], "fromDest", selectedObj);
            }
            if (id.indexOf("toDest") > -1) {
              self.$set(self.lead.trips[index], "toDest", selectedObj);
            }
          },
          focus: function (event, ui) {
            let index = id.split("_")[1];
            if (id.indexOf("fromDest") > -1) {
              self.$set(self.lead.trips[index], "fromDest", ui.item);
            }
            if (id.indexOf("toDest") > -1) {
              self.$set(self.lead.trips[index], "toDest", ui.item);
            }
          },
        })
        .autocomplete("instance")._renderItem = function (ul, item) {
          return $("<li>")
            .append(
              "<div>" +
              item.city +
              ", " +
              item.country +
              "<span class='pull-right'>" +
              item.airport_code +
              "</span><br><span class='clsAirportName'>" +
              item.airport +
              "</span></div>"
            )
            .appendTo(ul);
        };
      $("#" + id).on("click", function () {
        $(this).select();
      });
    },
    generateSearchResult(type, list, listIndex) {
      let html = `<div class="search-result-list">
							<!-- Airport parents list start -->
							<ul class="search-airport">`;
      list.forEach((element, index) => {
        html += `<li>
							<div class="search-airport" @click="selectLocation('${type}',${listIndex},${index})">
								<div>
									<span>${element.city}, ${element.country}</span>
									<p>(All Airports)</p>
								</div>
							</div>
								<!-- Airport child list start -->
								<ul>`;
        element.airports.forEach((airport, subIndex) => {
          html += `<li @click="selectLocation('${type}',${listIndex},${index},${subIndex})">
								<div class="search-airport">
									<span>${airport.airport}</span>
									<p>${airport.city}</p>
								</div>
								<a href="javascript:;">${airport.airport_code}</a>
							</li>`;
        });
        html += ` </ul> </li>`;
      });
      html += `
								</ul>
								<!-- Airport parent list end -->
							</div>`;
      return html;
    },

    selectLocation(type, airport, listIndex, isCity = false) {
      if (airport) {
        this.$set(this.lead.trips, listIndex, {
          ...this.lead.trips[listIndex],
          [type === "fromDest"
            ? "fromDestSelectedLocation"
            : "toDestSelectedLocation"]: airport,
          [type === "fromDest" ? "from" : "to"]: {
            country: airport.country,
            city: airport.city,
            code: isCity ? airport.city_code : airport.airport_code,
          },
        });

        document.getElementById(`${type}_${listIndex}`).value = `${isCity ? airport.city_code : airport.airport_code
          } - ${airport.city}`;
      }
      $(`#${type}SearchList_${listIndex}`).removeClass("show");
    },

    loadAirportLookup(id, type) {
      const _vm = this;
      $(`#${type}_${id}`).keyup(function () {
        var query = $(this).val();
        if (query.length > 2) {
          _vm.$set(_vm.lead.trips[id], `${type}Loading`, true);
          $(`#${type}SearchList_${id}`).addClass("show");
          $.ajax({
            url:
              process.env.VUE_APP_API_URL +
              "/search-airport?search_key=" +
              query,
            type: "GET",
            success: function (data) {
              _vm.lead.trips[id][`${type}Location`] = data.data;
              _vm.$set(_vm.lead.trips[id], `${type}Loading`, false);
            },
          });
        }
      });
      $(document).on("click", function (e) {
        if ($(e.target).closest(`#${type}SearchList_${id}`).length === 0) {
          $(`#${type}SearchList_${id}`).removeClass("show");
        }
      });
    },
    getAirlinesCode() {
      let _vm = this;
      const queryString = this.$route.params.id
        ? "?id=" + this.$route.params.id
        : "";
      this.axios
        .get("/airline-list" + queryString, _vm.airlinesList)
        .then(function (response) {
          _vm.airlinesList = response.data.data;
          if (parseInt(_vm.$route.params.id) > 0) {
            _vm.getDetail();
          }
        })
        .catch(function () { });
    },
    customLabel({ name, code }) {
      return `${name} - ${code}`;
    },
  },
  mounted() {
    this.getCompany();
    this.getAirlinesCode();
    // this.addNewSegment();
    this.getDetail();
    this.isMounted = true;
    // if (this.lead.client.client_phones.length === 1) {
    //   this.setPreferredPhone(0);
    // }
    // if (this.lead.client.client_phones.length === 1) {
    //   this.setPreferredEmail(0);
    // }
  },
};
