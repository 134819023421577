import { _ } from "vue-underscore";
import Vue from "vue";

export default {
  data() {
    return {
      clientList: [],
      showLoader: false,
    };
  },

  computed: {},
  methods: {
    getClientList() {
      let _vm = this;
      _vm.showLoader = true;
      this.axios
        .get("/leads-by-client/" + this.$route.params.id)
        .then(function (response) {
          _vm.clientList = JSON.parse(atob(response.data.data));
          _vm.showLoader = false;
        })
        .catch(function () {});
    },
    createNewLead() {
      this.$router.push({
        path: "/lead/add-lead",
        query: { email: this.clientList[0].client.client_emails[0].value },
      });
    },
  },
  mounted() {
    this.getClientList();
    Vue.filter("formatdate", function (value) {
      if (!value) return "";
      return moment(String(value)).format("MMM-DD-YY");
    });
  },
};
