import { _ } from "vue-underscore";
import Vue from "vue";

export default {
  data() {
    return {
      quotation: {
        type: "Revenue",
        pcc: "",
        milageprograms: "",
      },
      uniqueLines: [],
      pccsList: [],
      milageprogramsList: [],
      Bags: [0, 1, 2, 3],
      PNR: "",
      pnrData: null,
      sep_loader: false,
      textAreaVisible: false,
      loading: false,
      editPNR: false,
      selectedCpm: 0,
      userId: JSON.parse(atob(localStorage.getItem('user'))).id
    };
  },
  props: {
    selectedQuotation: null,
    leadId: {},
    showModal: Boolean,
    minimized: Boolean,
    title: String,
    selectedLead: {},
  },
  watch: {
    "quotation.adult_miles": function () {
      this.calculatePrices();
    },
    "quotation.child_miles": function () {
      this.calculatePrices();
    },
    "quotation.infant_miles": function () {
      this.calculatePrices();
    },
    "quotation.adult_tax": function () {
      this.calculateNetPrices();
    },
    "quotation.child_tax": function () {
      this.calculateNetPrices();
    },
    "quotation.infant_tax": function () {
      this.calculateNetPrices();
    },
    selectedQuotation: {
      immediate: true,
      handler(newVal) {
        if (Object.keys(newVal).length > 0) {
          this.quotation = newVal;
          this.delayFetch();
          $("#expiredDate").val(this.quotation.expired_date);
        }
      },
    },
    uniqueLines(newVal) {
      const uniqueLinesArray = Object.values(newVal);
      if (uniqueLinesArray.length === 1) {
        this.quotation.carrier = uniqueLinesArray[0].code;
      }
    },
  },
  computed: {
    modalStyle() {
      return this.minimized
        ? { height: "40px", overflow: "hidden" }
        : { height: "100vh" };
    },
    uniqueLinesArray() {
      return Object.values(this.uniqueLines);
    },
    textareaHeight() {
      const lineCount = this.PNR.split("\n").length;
      const lineHeight = 30;
      const minHeight = 100;
      const height = lineCount * lineHeight;
      const finalHeight = Math.max(minHeight, height + 50);

      return `${finalHeight}px`;
    },
    isChildShow() {
      if (
        this.selectedLead &&
        this.selectedLead.trips &&
        this.selectedLead.trips.length > 0
      ) {
        return this.selectedLead.trips[0].children > 0;
      }
      return false;
    },
    isInfantShow() {
      if (
        this.selectedLead &&
        this.selectedLead.trips &&
        this.selectedLead.trips.length > 0
      ) {
        return this.selectedLead.trips[0].infants > 0;
      }
      return false;
    },
    shouldDisableSellingPriceField() {
      return this.selectedLead.assign_to == this.userId || this.hasSellingPricePermission;
    },
    hasSellingPricePermission() {
      const slugsList = this.$store.state.slugsList;
      const desiredData = "leads-add-selling-price-in-others-pq";
      let hasPermission = slugsList.includes(desiredData);
      return hasPermission;
    },
  },
  methods: {
    updateCpm() {
      const selectedProgram = this.milageprogramsList.find(
        (program) => program.id === parseInt(this.quotation.milageprograms)
      );
      if (selectedProgram) {
        this.selectedCpm = selectedProgram.cpm;
        this.calculatePrices();
      }
    },
    validateMiles(event, type) {
      event.target.value = event.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      this.calculatePrices();
    },
    validateTax(event, type) {
      event.target.value = event.target.value
        .replace(/[^0-9.]/g, "")
        .replace(/(\..*)\./g, "$1");
      this.calculateNetPrices();
    },
    calculatePrices() {
      const cpm = this.selectedCpm || 0;

      const adultMiles = parseFloat(this.quotation.adult_miles) || 0;
      const childMiles = parseFloat(this.quotation.child_miles) || 0;
      const infantMiles = parseFloat(this.quotation.infant_miles) || 0;

      this.quotation.adult_miles_price = (adultMiles * cpm).toFixed(2);
      this.quotation.child_miles_price = (childMiles * cpm).toFixed(2);
      this.quotation.infant_miles_price = (infantMiles * cpm).toFixed(2);

      this.calculateNetPrices();
    },
    calculateNetPrices() {
      const adultMilesPrice = parseFloat(this.quotation.adult_miles_price) || 0;
      const childMilesPrice = parseFloat(this.quotation.child_miles_price) || 0;
      const infantMilesPrice =
        parseFloat(this.quotation.infant_miles_price) || 0;

      const adultTax = parseFloat(this.quotation.adult_tax) || 0;
      const childTax = parseFloat(this.quotation.child_tax) || 0;
      const infantTax = parseFloat(this.quotation.infant_tax) || 0;

      this.quotation.adult_net = (adultMilesPrice + adultTax).toFixed(2);
      this.quotation.child_net = (childMilesPrice + childTax).toFixed(2);
      this.quotation.infant_net = (infantMilesPrice + infantTax).toFixed(2);
    },
    validatePage() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 10000);

      let _vm = this;

      _vm.quotation["lead_id"] = _vm.selectedLead.id;
      _vm.quotation["flight_description"] = _vm.PNR;
      _vm.quotation["flight_description_detail"] = _vm.pnrData;

      _vm.quotation.adult_selling_price =
        _vm.quotation.adult_selling_price || 0;
      _vm.quotation.child_selling_price =
        _vm.quotation.child_selling_price || 0;
      _vm.quotation.infant_selling_price =
        _vm.quotation.infant_selling_price || 0;

      if (_vm.quotation.id > 0) {
        _vm.axios
          .post("/quotation", {
            ..._vm.quotation,
          })
          .then(function () {
            _vm.showModal = false;
            _vm.$emit("updated");
          })
          .catch(function (error) { });
      } else {
        _vm.axios
          .post("/quotation", _vm.quotation)
          .then(function () {
            _vm.showModal = false;
            _vm.$emit("updated");
          })
          .catch(function (error) { });
      }
    },
    toggleMinimize() {
      this.$emit("update:minimized", !this.minimized);
    },
    getPccs() {
      let _vm = this;
      this.axios
        .get("/quotation-pcc")
        .then(function (response) {
          _vm.pccsList = response.data.data;
        })
        .catch(function () { });
    },
    getMilagePrograms() {
      let _vm = this;
      this.axios
        .get("/quotation-milage-program")
        .then(function (response) {
          _vm.milageprogramsList = response.data.data;
        })
        .catch(function () { });
    },
    validatePNR() {
      let _vm = this;
      _vm.sep_loader = true;
      let leadNumber = this.selectedLead.id;
      _vm.PNR = this.PNR.split("\n")
        .map((line) => line.trim().replace(/\s/g, " ").replace(/ +/g, " "))
        .join("\n");

      _vm.axios
        .post("/validate-pnr", {
          pnr: _vm.PNR,
          lead_id: leadNumber,
        })
        .then(function (response) {
          _vm.pnrData = response.data.data;
          _vm.uniqueLines = _vm.pnrData.dictionary.airlines;
          _vm.textAreaVisible = true;
          _vm.sep_loader = false;
        })
        .catch(function () {
          _vm.sep_loader = false;
        });
    },
    delayFetch: _.debounce(function () {
      let _vm = this;
      let flightDescription = _vm.selectedQuotation.flight_description_detail;
      if (flightDescription) {
        _vm.pnrData = flightDescription;
        _vm.uniqueLines = flightDescription.dictionary.airlines;
      }
      _vm.textAreaVisible = true;
      _vm.editPNR = true;
    }, 400),

    editTextArea() {
      let _vm = this;
      _vm.textAreaVisible = false;
    },
  },
  mounted() {
    let _vm = this;
    if (Object.keys(_vm.selectedQuotation).length > 0) {
      _vm.quotation = _vm.selectedQuotation;
      _vm.$nextTick(() => {
        _vm.delayFetch();
        $("#expiredDate").val(_vm.quotation.expired_date);
      });
    }
    this.getPccs();
    this.getMilagePrograms();
  },
};
