import { render, staticRenderFns } from "./create-Multisale.vue?vue&type=template&id=ddc46c34"
import script from "./create-Multisale.vue?vue&type=script&lang=js"
export * from "./create-Multisale.vue?vue&type=script&lang=js"
import style0 from "./create-Multisale.vue?vue&type=style&index=0&id=ddc46c34&prod&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports