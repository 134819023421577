import { _ } from "vue-underscore";

export default {
  data() {
    return {
      leadLogList: [],
      showLoader: false,
      currentPage: 1,
      itemsPerPage: 50,
      nextPageUrl: "",
      previousPageUrl: "",
      showCurrentPage: 1,
    };
  },
  computed: {
    totalPages() {
      return Math.ceil(this.leadLogList.length / this.itemsPerPage);
    },
    paginatedLeadLogList() {
      const startIndex = (this.currentPage - 1) * this.itemsPerPage;
      const endIndex = startIndex + this.itemsPerPage;
      return this.leadLogList.slice(startIndex, endIndex);
    },
  },
  methods: {
    // formatDateTime(timestamp) {
    //     const date = new Date(timestamp);
    //     const months = [
    //       "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //       "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //     ];

    //     const month = months[date.getMonth()];
    //     const day = date.getDate().toString().padStart(2, '0');
    //     const year = date.getFullYear().toString().slice(2);
    //     const time = timestamp.slice(11, 16);

    //     return `${month}/${day}/${year} ${time}`;
    //   },
    getLeadLogList() {
      let _vm = this;
      _vm.showLoader = true;
      this.axios
        .get("/lead-logs/" + this.$route.params.id)
        .then(function (response) {
          _vm.leadLogList = response.data.data.data;
          _vm.nextPageUrl = response.data.data.next_page_url;
          _vm.previousPageUrl = response.data.data.prev_page_url;
          _vm.showCurrentPage = response.data.data.current_page;
          _vm.showLoader = false;
        })
        .catch(function () {});
    },
    nextPage() {
      if (this.nextPageUrl) {
        this.axios.get(this.nextPageUrl).then((response) => {
          this.leadLogList = response.data.data.data;
          this.nextPageUrl = response.data.data.next_page_url;
          this.previousPageUrl = response.data.data.prev_page_url;
          this.showCurrentPage = response.data.data.current_page;
        });
      }
    },
    previousPage() {
      // }'
      if (this.previousPageUrl) {
        this.axios.get(this.previousPageUrl).then((response) => {
          this.leadLogList = response.data.data.data;
          this.nextPageUrl = response.data.next_page_url;
          this.previousPageUrl = response.data.data.prev_page_url;
          this.showCurrentPage = response.data.data.current_page;
        });
      }
    },
  },
  mounted() {
    this.getLeadLogList();
  },
};
