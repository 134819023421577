<template>
  <div id="askExpert-modal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="background-color: white;"> 
              <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel2">Ask Expert</h5>
                  <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                      <span aria-hidden="true">&times;</span>
                  </button>
              </div>             
              <div class="modal-body">
                  <textarea type="text" id="Comment" style="border-radius: 8px;" name="description" rows="4"
                      placeholder="Enter Comment..." v-model="askExpertComment"
                      class="au-input au-input--full form-control placeholderpadding"></textarea>
              </div>
              <div class="modal-footer">
                  <button type="button" class="btn btn-secondary" data-dismiss="modal"
                      @click="$emit('close')">Close</button>
                  <button type="button" class="btn btn-primary" @click="askExert">Save</button>
              </div>
            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vue from 'vue'
export default {
  name: 'askExpert-modal',
  data: () => ({
    askExpertComment:''
  }),
  props: {
    selectedLead: Object,
    selectedClient: Object,
    getDetail:{
      type: Function,
      required: true,
    },
  },
  methods: {
    askExert() {
            let _vm = this;
            const comment =  _vm.askExpertComment;
            this.axios
                .post("/ask-expert", { lead_id: this.$store.state.leadId ,comment})
                .then(function (response) {
                    _vm.askExertlist = response.data.data;
                    _vm.$emit('close');
                    _vm.getDetail();

                })
                .catch(function (error) {

                });
        },
    
  },
  computed: {
    

  },
  mounted() {
    
  }
}
</script>
<style scoped>
.modal-mask {
    position: fixed;
    z-index: 9998;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, .5);
    display: table;
    transition: opacity .3s ease;
}

.modal-wrapper {
    display: table-cell;
    vertical-align: middle;
}

.modal-body {
    max-height: calc(100vh - 180px);
    overflow-y: auto;
}

</style>
