<template>
  <b-container fluid>
    <b-row>
      <b-col sm="12">
        <table class="table table-borderless table-striped table-earning small" style="background-color: #553b9b61">

          <thead>
            <tr>
              <th width="11%">Created</th>
              <th width="9%">Sale Id</th>
              <th width="5%"></th>
              <th width="7%">Container</th>
              <th width="9%">lead Id</th>
              <th width="12%">Client</th>
              <th width="9%">Agent</th>
              <th width="9%">GP Bonus</th>
              <th width="5%">T Assist</th>
              <th width="7%">Selling Price</th>
              <th width="7%">Net Profit</th>
              <th width="8%">status</th>
              <th width="2%"></th>
            </tr>
          </thead>

        </table>

        <!-- <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasOpenSales">
              <h5>Open</h5>
              <span @click="assignedSales('Open')" style="cursor: pointer;">
                <i class="fa-solid fa-angle-down ml-2"></i></span>
            </div> -->

        <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasOpenSales" @click="toggleTable(0)"
          style="cursor: pointer;">
          <h5>Open</h5>
          <div v-if="sale_ref.Open.length > 10">
            <span>Total Sales:
              {{ sale_ref.Open.length }} </span> <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(0)">
              <span v-if="!isTableExpanded1" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>
          <div v-else>
            <span>Total Sales: {{ sale_ref.Open.length }} </span> <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(0)">
              <span v-if="!isTableExpanded1" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>
        </div>

        <table class="table table-borderless table-earning small" width="100%" style="width: 100%"
          v-if="hasOpenSales && isTableExpanded1">

          <tbody v-for="(sale, k) in sale_ref.Open" :key="k" width="100%" style="width: 100%">
            <tr class="tr-shadow">
              <td style="padding: 0;">
                <!-- <template > -->
                <table v-if="sale.status === 'Open'" width="100%" style="width: 100%">
                  <tbody width="100%" style="width: 100%">
                    <tr @click="toggleOpen('Open' + k)" style="cursor: pointer;">
                      <td width="11%">{{ sale.created_at | formatDateShort }}</td>
                      <td width="9%"><router-link :to="'/sales_manage/' + sale.sales[0].id + '?ref=' + sale.id"
                          class="item text-decoration-none" data-toggle="tooltip" data-placement="top"
                          target="_blank">{{
                            sale.id }}</router-link></td>
                      <td width="5%">
                        <span :style="sale.sales[0].client_type === null ? 'visibility:hidden' : ''">
                          <span :class="{
                            'badge badge-danger': sale.sales[0].client_type == 'DR',
                            'badge badge-warning': sale.sales[0].client_type == 'RF',
                            'badge badge-info': sale.sales[0].client_type == 'RE',
                            'badge badge-dark': sale.sales[0].client_type == 'CR'
                          }">
                            {{ sale.sales[0].client_type }}
                          </span>
                        </span>
                      </td>
                      <td width="7%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].sale_container_id : 'N/A' }}
                      </td>
                      <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].lead_id : 'N/A' }}</td>
                      <td width="12%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].client_name : 'N/A' }}</td>
                      <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].created_by_detail : 'N/A' }}
                      </td>
                      <td width="9%">{{ sale.tip || 0 }}</td>
                      <td width="5%"></td>
                      <td width="7%">{{ calculateTotalOpenSumOfSellingPrices(sale) }}</td>
                      <td width="7%">{{ sale.net_profit - sale.wire_fee }}</td>
                      <td width="8%">{{ sale.status }}</td>
                      <td width="2%" @click.stop="toggleOpen('Open' + k)">
                        <i v-if="expandedRow !== 'Open' + k" class="fa-solid fa-angle-down cursor-pointer"></i>
                        <i v-else class="fa-solid fa-angle-up cursor-pointer"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <!-- </template> -->
            </tr>
            <tr class="text-muted bg-light" v-show="expandedRow === 'Open' + k">
              <td>
                <table>
                  <thead>
                    <tr>
                      <th class="col-md-1">Section ID</th>
                      <th class="col-md-1"></th>
                      <th class="col-md-2">PNR</th>
                      <th class="col-md-1">Type</th>
                      <th class="col-md-2">No. of PAX</th>
                      <th class="col-md-2">Travel Date</th>
                      <th class="col-md-2">Supplier</th>
                      <th class="col-md-2">Selling Price</th>
                      <th class="col-md-2">Net Profit</th>
                      <th class="col-md-2">status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, i) in sale.sales" :key="'Open-container-' + i">
                      <td>
                        <router-link :to="'/sales_manage/' + item.id" class="item text-decoration-none"
                          data-toggle="tooltip" data-placement="top" target="_blank">{{
                            item.id }}</router-link>
                      </td>
                      <td>
                        <span :style="item.client_type === null ? 'visibility:hidden' : ''">
                          <span :class="{
                            'badge badge-danger': item.client_type == 'DR',
                            'badge badge-warning': item.client_type == 'RF',
                            'badge badge-info': item.client_type == 'RE',
                            'badge badge-dark': item.client_type == 'CR'
                          }">
                            {{ item.client_type }}
                          </span>
                        </span>
                      </td>
                      <td>{{ item.pnr }}</td>
                      <td>{{ item.issue_program }}</td>
                      <!-- <td>
                            <router-link :to="'lead/' + item.lead_number + '#price-quotes'"
                              class="item text-decoration-none" data-toggle="tooltip" data-placement="top"
                              target="_blank">{{ item.lead_number
                              }}</router-link>
                          </td> -->
                      <td> {{ item.financial_informations && item.financial_informations.financial_pax ?
                        item.financial_informations.financial_pax.length : 0 }}</td>
                      <td> {{ item.travel_date | formatDate }}</td>
                      <td>{{ item.transaction_type }}</td>
                      <td>{{ calculateTotalSellingPrice(item.financial_informations.financial_pax) }}</td>
                      <td>{{ item.financial_informations.agents_profit || 0 }}</td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasReceivedSales">
              <h5>Received</h5>
              <span @click="assignedSales('Received')" style="cursor: pointer;">
                <i class="fa-solid fa-angle-down ml-2"></i></span>
            </div> -->

        <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasReceivedSales" @click="toggleTable(1)"
          style="cursor: pointer;">
          <h5>Received</h5>

          <div v-if="sale_ref.Received.length > 10">
            <span>Total Sales:
              {{ sale_ref.Received.length }} </span> <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(1)">
              <span v-if="!isTableExpanded2" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>
          <div v-else>
            <span>Total Sales: {{ sale_ref.Received.length }} </span> <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(1)">
              <span v-if="!isTableExpanded2" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>

          <!-- <div v-if="totalReceived > 10"> -->
          <!-- <span  @click="assignedSales('Received')" style="cursor: pointer;">Total:
                {{ totalReceived }} </span>  -->
          <!-- <i class="fa-solid" style="cursor: pointer;" @click.stop="toggleTable(1)">
                <span v-if="!isTableExpanded2" class="fa-angle-down"></span>
                <span v-else class="fa-angle-up"></span>
              </i>
            </div>
            <div v-else> -->
          <!-- <span>Total: {{ totalReceived }} </span>  -->
          <!-- <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(1)">
                <span v-if="!isTableExpanded2" class="fa-angle-down"></span>
                <span v-else class="fa-angle-up"></span>
              </i>
            </div> -->
        </div>
        <table class="table table-borderless table-earning small" width="100%" style="width: 100%"
          v-if="hasReceivedSales && isTableExpanded2">

          <tbody v-for="(sale, R) in sale_ref.Received" :key="R" width="100%" style="width: 100%">
            <tr class="tr-shadow">
              <td style="padding: 0;">
                <table v-if="sale.status === 'Received'" width="100%" style="width: 100%">
                  <tbody width="100%" style="width: 100%">
                    <tr @click="toggleOpen('Received' + R)" style="cursor: pointer;">
                      <td width="11%">{{ sale.created_at | formatDateShort }}</td>
                      <td width="9%"><router-link :to="'/sales_manage/' + sale.sales[0].id + '?ref=' + sale.id"
                          class="item text-decoration-none" data-toggle="tooltip" data-placement="top"
                          target="_blank">{{
                            sale.id }}</router-link></td>
                      <td width="5%">
                        <span :style="sale.sales[0].client_type === null ? 'visibility:hidden' : ''">
                          <span :class="{
                            'badge badge-danger': sale.sales[0].client_type == 'DR',
                            'badge badge-warning': sale.sales[0].client_type == 'RF',
                            'badge badge-info': sale.sales[0].client_type == 'RE',
                            'badge badge-dark': sale.sales[0].client_type == 'CR'
                          }">
                            {{ sale.sales[0].client_type }}
                          </span>
                        </span>
                      </td>
                      <td width="7%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].sale_container_id : 'N/A' }}
                      </td>
                      <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].lead_id : 'N/A' }}</td>
                      <td width="12%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].client_name : 'N/A' }}</td>
                      <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].created_by_detail : 'N/A' }}
                      </td>
                      <td width="9%">{{ sale.tip || 0 }}</td>
                      <td width="5%">{{ }}</td>
                      <td width="7%">{{ calculateTotalReceivedSumOfSellingPrices(sale) }}</td>
                      <td width="7%">{{ sale.net_profit - sale.wire_fee }}</td>
                      <td width="8%">{{ sale.status }}</td>
                      <td width="2%" @click.stop="toggleOpen('Received' + R)">
                        <i v-if="expandedRow !== 'Received' + R" class="fa-solid fa-angle-down cursor-pointer"></i>
                        <i v-else class="fa-solid fa-angle-up cursor-pointer"></i>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
            <tr class="text-muted bg-light" v-show="expandedRow === 'Received' + R">
              <td>
                <table>
                  <thead>
                    <tr>
                      <th class="col-md-1">Section ID</th>
                      <th class="col-md-1"></th>
                      <th class="col-md-2">PNR</th>
                      <th class="col-md-1">Type</th>
                      <th class="col-md-2">No. of PAX</th>
                      <th class="col-md-2">Travel Date</th>
                      <th class="col-md-2">Supplier</th>
                      <th class="col-md-2">Selling Price</th>
                      <th class="col-md-2">Net Profit</th>
                      <th class="col-md-2">status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, r) in sale.sales" :key="'Received-container-' + r">
                      <td>
                        <router-link :to="'/sales_manage/' + item.id" class="item text-decoration-none"
                          data-toggle="tooltip" data-placement="top" target="_blank">{{
                            item.id }}</router-link>
                      </td>
                      <td>
                        <span :style="item.client_type === null ? 'visibility:hidden' : ''">
                          <span :class="{
                            'badge badge-danger': item.client_type == 'DR',
                            'badge badge-warning': item.client_type == 'RF',
                            'badge badge-info': item.client_type == 'RE',
                            'badge badge-dark': item.client_type == 'CR'
                          }">
                            {{ item.client_type }}
                          </span>
                        </span>
                      </td>
                      <td>{{ item.pnr }}</td>
                      <!-- <td>{{ item.created_at | formatDateTime }}</td> -->
                      <td>{{ item.issue_program }}</td>
                      <!-- <td>
                            <router-link :to="'lead/' + item.lead_number + '#price-quotes'"
                              class="item text-decoration-none" data-toggle="tooltip" data-placement="top"
                              target="_blank">{{ item.lead_number
                              }}</router-link>
                          </td> -->
                      <td> {{ item.financial_informations && item.financial_informations.financial_pax ?
                        item.financial_informations.financial_pax.length : 0 }}</td>
                      <td>{{ item.travel_date | formatDate }} </td>
                      <td>{{ item.transaction_type }}</td>
                      <td>{{ calculateTotalSellingPrice(item.financial_informations.financial_pax) }}</td>
                      <td>{{ item.financial_informations.agents_profit || 0 }}</td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasTicketedSales">
              <h5>Ticketed</h5>
              <span v-if="totalTicketed > 10" @click="assignedSales('Ticketed')" style="cursor: pointer;">
              <i class="fa-solid fa-angle-down ml-2"></i></span>
            </div> -->

        <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasTicketedSales" @click="toggleTable(3)"
          style="cursor: pointer;">
          <h5>Ticketed</h5>

          <div v-if="sale_ref.Ticketed.length > 10">
            <span>Total Sales:
              {{ sale_ref.Ticketed.length }} </span> <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(3)">
              <span v-if="!isTableExpanded4" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>
          <div v-else>
            <span>Total Sales: {{ sale_ref.Ticketed.length }} </span> <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(3)">
              <span v-if="!isTableExpanded4" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>

          <!-- <div v-if="totalTicketed > 10"> -->
          <!-- <span  @click="assignedSales('Ticketed')" style="cursor: pointer;">Total:
                {{ totalTicketed }} </span>  -->
          <!-- <i class="fa-solid" style="cursor: pointer;" @click.stop="toggleTable(3)">
                <span v-if="!isTableExpanded4" class="fa-angle-down"></span>
                <span v-else class="fa-angle-up"></span>
              </i>
            </div>
            <div v-else> -->
          <!-- <span>Total: {{ totalTicketed }} </span>  -->
          <!-- <i class="fa-solid" style="cursor: pointer;"
              @click.stop="toggleTable(3)">
                <span v-if="!isTableExpanded4" class="fa-angle-down"></span>
                <span v-else class="fa-angle-up"></span>
              </i>
            </div> -->
        </div>
        <table class="table table-borderless table-earning small" width="100%" style="width: 100%"
          v-if="hasTicketedSales && isTableExpanded4">

          <tbody v-for="(sale, T) in sale_ref.Ticketed" :key="T" width="100%" style="width: 100%">
            <tr class="tr-shadow">
              <td style="padding: 0;">
                <table v-if="sale.status === 'Ticketed'" width="100%" style="width: 100%">
                  <tr @click="toggleOpen('Ticketed' + T)" style="cursor: pointer;">
                    <td width="11%">{{ sale.created_at | formatDateShort }}</td>
                    <td width="9%"> <router-link :to="'/sales_manage/' + sale.sales[0].id + '?ref=' + sale.id"
                        class="item text-decoration-none" data-toggle="tooltip" data-placement="top" target="_blank">{{
                          sale.id }}</router-link></td>

                    <td width="5%">
                      <span :style="sale.sales[0].client_type === null ? 'visibility:hidden' : ''">
                        <span :class="{
                          'badge badge-danger': sale.sales[0].client_type == 'DR',
                          'badge badge-warning': sale.sales[0].client_type == 'RF',
                          'badge badge-info': sale.sales[0].client_type == 'RE',
                          'badge badge-dark': sale.sales[0].client_type == 'CR'
                        }">
                          {{ sale.sales[0].client_type }}
                        </span>
                      </span>
                    </td>
                    <td width="7%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].sale_container_id : 'N/A' }}
                    </td>
                    <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].lead_id : 'N/A' }}</td>
                    <td width="12%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].client_name : 'N/A' }}</td>
                    <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].created_by_detail : 'N/A' }}
                    </td>
                    <td width="9%">{{ sale.tip || 0 }}</td>
                    <td width="5%">{{ }}</td>
                    <td width="7%">{{ calculateTotalTicketedSumOfSellingPrices(sale) }}</td>
                    <td width="7%">{{ sale.net_profit - sale.wire_fee }}</td>
                    <td width="8%">{{ sale.status }}</td>
                    <td width="2%" @click.stop="toggleOpen('Ticketed' + T)">
                      <i v-if="expandedRow !== 'Ticketed' + T" class="fa-solid fa-angle-down cursor-pointer"></i>
                      <i v-else class="fa-solid fa-angle-up cursor-pointer"></i>
                    </td>
                  </tr>
                </table>
              </td>
            </tr>
            <tr class="text-muted bg-light" v-show="expandedRow === 'Ticketed' + T">
              <td>
                <table>
                  <thead>
                    <tr>
                      <th class="col-md-1">Section ID</th>
                      <th class="col-md-1"></th>
                      <th class="col-md-2">PNR</th>
                      <th class="col-md-1">Type</th>
                      <th class="col-md-2">No. of PAX</th>
                      <th class="col-md-2">Travel Date</th>
                      <th class="col-md-2">Supplier</th>
                      <th class="col-md-2">Selling Price</th>
                      <th class="col-md-2">Net Profit</th>
                      <th class="col-md-2">status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, t) in sale.sales" :key="'Ticketed-container-' + t">
                      <td>
                        <router-link :to="'/sales_manage/' + item.id" class="item text-decoration-none"
                          data-toggle="tooltip" data-placement="top" target="_blank">{{
                            item.id }}</router-link>
                      </td>
                      <td>
                        <span :style="item.client_type === null ? 'visibility:hidden' : ''">
                          <span :class="{
                            'badge badge-danger': item.client_type == 'DR',
                            'badge badge-warning': item.client_type == 'RF',
                            'badge badge-info': item.client_type == 'RE',
                            'badge badge-dark': item.client_type == 'CR'
                          }">
                            {{ item.client_type }}
                          </span>
                        </span>
                      </td>
                      <td>{{ item.pnr }}</td>
                      <td>{{ item.issue_program }}</td>
                      <!-- <td>
                            <router-link :to="'lead/' + item.lead_number + '#price-quotes'"
                              class="item text-decoration-none" data-toggle="tooltip" data-placement="top"
                              target="_blank">{{ item.lead_number
                              }}</router-link>
                          </td> -->
                      <td>{{ item.financial_informations && item.financial_informations.financial_pax ?
                        item.financial_informations.financial_pax.length : 0 }}</td>
                      <td>{{ item.travel_date | formatDate }} </td>
                      <td>{{ item.transaction_type }}</td>
                      <td>{{ calculateTotalSellingPrice(item.financial_informations.financial_pax) }}</td>
                      <td>{{ item.financial_informations.agents_profit || 0 }}</td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>
            </tr>
          </tbody>
        </table>

        <!-- <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasClosedSales">
              <h5>Closed</h5>
              <span v-if="totalClosed > 10" @click="assignedSales('Closed')" style="cursor: pointer;">
              <i class="fa-solid fa-angle-down ml-2"></i></span>
            </div> -->

        <div class="breadcrumb p-2 d-flex justify-content-between" v-if="hasClosedSales" @click="toggleTable(5)"
          style="cursor: pointer;">
          <h5>Closed</h5>
          <div v-if="totalClosed > 10">
            <!-- <span  @click="assignedSales('Closed')" style="cursor: pointer;">Total:
                {{ totalClosed }} </span>  -->
            <i class="fa-solid" style="cursor: pointer;" @click.stop="toggleTable(5)">
              <span v-if="!isTableExpanded6" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>
          <div v-else>
            <!-- <span>Total: {{ totalClosed }} </span>  -->
            <i class="fa-solid" style="cursor: pointer;" @click.stop="toggleTable(5)">
              <span v-if="!isTableExpanded6" class="fa-angle-down"></span>
              <span v-else class="fa-angle-up"></span>
            </i>
          </div>
        </div>


        <table class="table table-borderless table-earning small" width="100%" style="width: 100%"
          v-if="hasClosedSales && isTableExpanded6">

          <tbody v-for="(sale, C) in sale_ref.Closed" :key="C" width="100%" style="width: 100%">
            <tr class="tr-shadow" @click="toggleOpen('Closed' + C)" style="cursor: pointer;">
              <td style="padding: 0;">
                <table v-if="sale.status === 'Closed'" width="100%" style="width: 100%">
                  <!-- {{ leadList }} -->
                  <td width="11%">{{ sale.created_at | formatDateShort }}</td>
                  <td width="9%"> <router-link :to="'/sales_manage/' + sale.sales[0].id + '?ref=' + sale.id"
                      class="item text-decoration-none" data-toggle="tooltip" data-placement="top" target="_blank">{{
                        sale.id }}</router-link></td>
                  <td width="5%">
                    <span :style="sale.sales[0].client_type === null ? 'visibility:hidden' : ''">
                      <span :class="{
                        'badge badge-danger': sale.sales[0].client_type == 'DR',
                        'badge badge-warning': sale.sales[0].client_type == 'RF',
                        'badge badge-info': sale.sales[0].client_type == 'RE',
                        'badge badge-dark': sale.sales[0].client_type == 'CR'
                      }">
                        {{ sale.sales[0].client_type }}
                      </span>
                    </span>
                  </td>
                  <td width="7%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].sale_container_id : 'N/A' }}
                  </td>
                  <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].lead_id : 'N/A' }}</td>
                  <td width="12%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].client_name : 'N/A' }}</td>
                  <td width="9%">{{ sale.sales && sale.sales.length > 0 ? sale.sales[0].created_by_detail : 'N/A' }}
                  </td>
                  <td width="9%">{{ sale.tip || 0 }}</td>
                  <td width="5%">{{ }}</td>
                  <td width="7%">{{ calculateTotalClosedSumOfSellingPrices(sale) }}</td>
                  <td width="7%">{{ sale.net_profit - sale.wire_fee }}</td>
                  <td width="8%">{{ sale.status }}</td>
                  <td width="2%" @click.stop="toggleOpen('Closed' + C)">
                    <i v-if="expandedRow !== 'Closed' + C" class="fa-solid fa-angle-down cursor-pointer"></i>
                    <i v-else class="fa-solid fa-angle-up cursor-pointer"></i>
                  </td>
                </table>
              </td>
            </tr>
            <tr class="text-muted bg-light" v-show="expandedRow === 'Closed' + C">
              <td>
                <table>
                  <thead>
                    <tr>
                      <th class="col-md-1">Section ID</th>
                      <th class="col-md-1"></th>
                      <th class="col-md-2">PNR</th>
                      <th class="col-md-1">Type</th>
                      <th class="col-md-2">No. of PAX</th>
                      <th class="col-md-2">Travel Date</th>
                      <th class="col-md-2">Supplier</th>
                      <th class="col-md-2">Selling Price</th>
                      <th class="col-md-2">Net Profit</th>
                      <th class="col-md-2">status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(item, c) in sale.sales" :key="'Closed-container-' + c">
                      <td>
                        <router-link :to="'/sales_manage/' + item.id" class="item text-decoration-none"
                          data-toggle="tooltip" data-placement="top" target="_blank">{{
                            item.id }}</router-link>
                      </td>
                      <td>
                        <span :style="item.client_type === null ? 'visibility:hidden' : ''">
                          <span :class="{
                            'badge badge-danger': item.client_type == 'DR',
                            'badge badge-warning': item.client_type == 'RF',
                            'badge badge-info': item.client_type == 'RE',
                            'badge badge-dark': item.client_type == 'CR'
                          }">
                            {{ item.client_type }}
                          </span>
                        </span>
                      </td>
                      <td>{{ item.pnr }}</td>
                      <td>{{ item.issue_program }}</td>
                      <!-- <td>
                    <router-link :to="'lead/' + item.lead_number + '#price-quotes'" class="item text-decoration-none"
                      data-toggle="tooltip" data-placement="top" target="_blank">{{ item.lead_number
                      }}</router-link>
                  </td> -->
                      <td>{{ item.financial_informations && item.financial_informations.financial_pax ?
                        item.financial_informations.financial_pax.length : 0 }}</td>
                      <td>{{ item.travel_date | formatDate }} </td>
                      <td>{{ item.transaction_type }}</td>
                      <td>{{ calculateTotalSellingPrice(item.financial_informations.financial_pax) }}</td>
                      <td>{{ item.financial_informations.agents_profit || 0 }}</td>
                      <td>{{ item.status }}</td>
                    </tr>
                  </tbody>
                </table>
              </td>

            </tr>
          </tbody>
        </table>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import headerList from "@/components/common/header-list.vue";
export default {
  data: () => ({
    salesRefList: [],
    error: "",
    searchText: "",
    showLoader: false,
    teamList: [],
    usersList: [],
    opened: [],
    number: 0,
    expandedRow: null,
    totalOpen: 0,
    totalReceived: 0,
    totalTicketed: 0,
    totalClosed: 0,
    sale_ref: {},
    isTableExpanded1: false,
    isTableExpanded2: true,
    isTableExpanded3: false,
    isTableExpanded4: false,
    isTableExpanded5: false,
    isTableExpanded6: false,
    // openSales: [],

  }),
  components: { "header-list": headerList },
  computed: {
    hasOpenSales() {
      return this.salesRefList.some(sale => sale.status === 'Open');
    },
    hasReceivedSales() {
      return this.salesRefList.some(sale => sale.status === 'Received');
    },
    hasTicketedSales() {
      return this.salesRefList.some(sale => sale.status === 'Ticketed');
    },
    hasClosedSales() {
      return this.salesRefList.some(sale => sale.status === 'Closed');
    },
  },
  methods: {
    calculateTotalSellingPrice(financialPax) {
      if (!financialPax || !Array.isArray(financialPax)) return 0;

      let totalSellingPrice = 0;
      financialPax.forEach(pax => {
        if (pax.selling_price) {
          totalSellingPrice += parseFloat(pax.selling_price);
        }
      });
      return totalSellingPrice;
    },
    calculateTotalOpenSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach(item => {
        if (item.financial_informations && item.financial_informations.financial_pax) {
          totalSum += parseFloat(this.calculateTotalSellingPrice(item.financial_informations.financial_pax).toFixed(2));
        }
      });
      return totalSum;
    },
    calculateTotalReceivedSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach(item => {
        if (item.financial_informations && item.financial_informations.financial_pax) {
          totalSum += parseFloat(this.calculateTotalSellingPrice(item.financial_informations.financial_pax).toFixed(2));
        }
      });
      return totalSum;
    },
    calculateTotalTicketedSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach(item => {
        if (item.financial_informations && item.financial_informations.financial_pax) {
          totalSum += parseFloat(this.calculateTotalSellingPrice(item.financial_informations.financial_pax).toFixed(2));
        }
      });
      return totalSum;
    },
    calculateTotalClosedSumOfSellingPrices(sale) {
      let totalSum = 0;
      sale.sales.forEach(item => {
        if (item.financial_informations && item.financial_informations.financial_pax) {
          totalSum += parseFloat(this.calculateTotalSellingPrice(item.financial_informations.financial_pax).toFixed(2));
        }
      });
      return totalSum;
    },
    // formatDateTime(timestamp) {
    //   const date = new Date(timestamp);
    //   const months = [
    //     "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //     "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //   ];

    //   const month = months[date.getMonth()];
    //   const day = date.getDate().toString().padStart(2, '0');
    //   const year = date.getFullYear().toString().slice(2);
    //   const time = timestamp.slice(11, 16);

    //   return `${month}/${day}/${year} ${time}`;
    // },
    toggleTable(tableNumber) {
      if (tableNumber === 0) {
        this.isTableExpanded1 = !this.isTableExpanded1;
      } else if (tableNumber === 1) {
        this.isTableExpanded2 = !this.isTableExpanded2;
      } else if (tableNumber === 2) {
        this.isTableExpanded3 = !this.isTableExpanded3;
      } else if (tableNumber === 3) {
        this.isTableExpanded4 = !this.isTableExpanded4;
      } else if (tableNumber === 4) {
        this.isTableExpanded5 = !this.isTableExpanded5;
      } else if (tableNumber === 5) {
        this.isTableExpanded6 = !this.isTableExpanded6;
      }
    },
    toggleOpen(index) {
      if (this.expandedRow === index) {
        this.expandedRow = null;
      } else {
        this.expandedRow = index;
      }
    },
    getNextPage(page) {
      this.$refs.paginate.selected = page;
      this.paginate.current_page = page;
      this.getAll();
    },
    // start sales details
    toggle(id) {
      const index = this.opened.indexOf(id);
      if (index > -1) {
        this.opened.splice(index, 1)
      } else {
        this.opened = [id];
      }
    },
    increment() {
      this.number++;
    },
    decrement() {
      this.number--;
    },
    // end sales details
    search() {
      this.getAll();
    },
    // getQueryString() {
    // 	let queryString = "?page=" + this.paginate.current_page + "&take=" + this.$store.state.recordTake;
    // 	queryString +=
    // 		this.searchText.length > 0 ? "&search=" + this.searchText : "";
    // 	return queryString;
    // },
    getTeam() {
      const _vm = this
      this.axios
        .get('/team-details', _vm.teamList)
        .then(function (response) {
          _vm.teamList = response.data.data
        })
        .catch(function () { })
    },
    getUserList() {
      let _vm = this;
      this.axios
        .get("/user-list")
        .then(function (response) {
          _vm.usersList = response.data.data;
        })
        .catch(function () { });
    },
    getAll() {
      let _vm = this;
      // let queryString = this.getQueryString();
      _vm.showLoader = true;
      this.axios
        .get("/sales-by-lead/" + this.$route.params.id)
        .then(function (response) {
          _vm.showLoader = false;
          let data = response.data.data;
          _vm.sale_ref = data;
          _vm.salesRefList = [];
          if (data.Open) {
            // _vm.totalOpen = data.Open.total_sale_references;
            for (const key in data.Open) {
              if (Object.hasOwnProperty.call(data.Open, key)) {
                _vm.salesRefList.push(data.Open[key]);
              }
            }
          }
          if (data.Received) {
            // _vm.totalReceived = data.Received.total_sale_references;
            for (const key in data.Received) {
              if (Object.hasOwnProperty.call(data.Received, key)) {
                _vm.salesRefList.push(data.Received[key]);
              }
            }
          }
          if (data.Ticketed) {
            // _vm.totalTicketed = data.Ticketed.total_sale_references;
            for (const key in data.Ticketed) {
              if (Object.hasOwnProperty.call(data.Ticketed, key)) {
                _vm.salesRefList.push(data.Ticketed[key]);
              }
            }
          }
          if (data.Closed) {
            // _vm.totalClosed = data.Closed.total_sale_references;
            for (const key in data.Closed) {
              if (Object.hasOwnProperty.call(data.Closed, key)) {
                _vm.salesRefList.push(data.Closed[key]);
              }
            }
          }
          _vm.paginate.page_count = _vm.getPageCount(
            response.data.data.total_record,
            _vm.$store.state.recordTake
          );
        })
        .catch(function (error) {
        });
    },
    assignedSales(status) {
      this.$router.push('sales/' + status)
    },
  },
  mounted() {
    this.getAll();
    this.getTeam();
    this.getUserList();
    // this.openSales = this.openSalesData;
  }
};

</script>

<style scoped>
.cursor-pointer {
  cursor: pointer;
}

.btn-secondary {
  background-color: rgb(138, 135, 135);
}

.bgsecondary1 {
  background-color: whitesmoke;
}

.secondary {
  background-color: gray;
  color: white;
  font-weight: 600;
  font-size: large;
}

.bbadge {
  width: 100px;
  font-size: 12px;
  width: 5em;
}

.notification-badge {
  position: relative;
  top: -16px;
  right: 3px;
  border-radius: 5px;
  padding: 2px 5px;
  height: 20px;
  width: 20px;
  background-color: red;
  color: white;
}

.breadcrumb {
  flex-wrap: wrap;
  padding: 0.75rem 1rem;
  margin-bottom: 1rem;
  list-style: none;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}
</style>
