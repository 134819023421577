<template>
    <div>
		<div class="row form-group">
			<h2 class="title-2 col-md-6 header-title">Client</h2>
		</div>
       <div class="row">
			<div class="col-lg-12">
				<!-- DATA TABLE -->
				<div class="table-responsive table--no-card m-b-30">
					<table
						class="table table-borderless table-striped table-earning"
					>
						<thead>
							<tr>
								<!-- <th>Local Time</th> -->
								<th>Lead Id</th>
								<th>Client Type</th>
								<th>Create Time</th>
								<th>Agent Details</th>
								<th>Class</th>
								<th>Trip Details</th>
								<th>PQ Count</th>
								<th>Status</th>
								<th>Type</th>
								<!-- <th>Ratings</th> -->
							</tr>
						</thead>
						<tbody v-if="showLoader">
							<tr>
								<td colspan="12" class="text-center">
									<img
										src="/images/icon/loader.gif"
										height="70"
										width="70"
									/>
								</td>
							</tr>
						</tbody>
						<tbody v-else>

								<tr
								class="tr-shadow"
								v-for="(lead, k) in clientList"
								:key="k"
								:class="lead.id==$route.params.id?'table-success':''"
							>
								<!-- <td>{{ lead.created_at | formatTime }}</td> -->
								<td>
									<router-link
											:to="'/lead/' + lead.id"
											class="item"
											target="_blank"
											data-toggle="tooltip"
											data-placement="top"
										>{{ lead.id}}</router-link>
								</td>
								<td></td>

								<td>{{ lead.created_at | formatDate  }}</td>
								<td>
									<select
										id="assign_to"
										v-model="lead.assign_to"
										disabled="disabled"
										class="au-input form-control"
										style="background:transparent;"
										>
										<option v-for="user in users" :key="user.id" :value="user.id"
										>{{ user.firstName }}</option>
									</select>
								</td>
								<td>{{ lead.trip_class==='Premium Economy'?'Premium':lead.travel_class }}</td>
								<td>
									<template v-if="lead.lead_locations[0]">
										{{lead.lead_locations[0].from_date | formatDate}}
									</template>
									{{lead.trip_code}} (X {{lead.passenger_count}})
								</td>
								<td>{{lead.quotations.length}}</td>
								<td>{{ }}</td>

								<td>{{ }}</td>

								<!-- <td>
									<select
										id="rate_request"
										v-model="lead.rate_request"
										class="au-input form-control"
										disabled="disabled"
										style="background:transparent; appearance: none; border: none; outline: none;"
										>
										<option value="☆">☆</option>
										<option value="☆☆">☆☆</option>
										<option value="☆☆☆">☆☆☆</option>
										<option value="☆☆☆☆">☆☆☆☆</option>
										<option value="☆☆☆☆☆">☆☆☆☆☆</option>
									</select
									>
								</td> -->

							</tr>
								<tr v-if="clientList.length === 0">
								<td colspan="10" class="text-center">
									No record found.
								</td>
							</tr>
							<tr class="spacer"></tr>
						</tbody>
					</table>
				</div>
				<!-- END DATA TABLE -->
			</div>
		</div>
    </div>
</template>
<style>
#assign_to {
	appearance: none;
	border: none;
	outline: none;
}
</style>
<script>
import paginationMixin from '@/Mixins/paginationMixin';
import {_} from "vue-underscore";
export default {
	data: () => ({
		clientList: [],
		users:[],
        error: "",
		showLoader: false,
	}),
	mixins: [paginationMixin],
	computed: {
		user() {
			return this.$store.state.userDetail;
		},
		userType() {
			return (this.user && this.user.id>0)?this.user.usertype.name:'';
		}
	},
	methods: {
		getUser() {
			let _vm = this;
			this.axios
				.get("/getusersbytypename?type=Agent")
				.then(function(response) {
					if(_vm.userType.toLowerCase()=='agent'){
						_vm.users =[];
						let user = _.find(response.data.data,{'id':_vm.$store.state.userDetail.id});
						if(user){
							_vm.users.push(user);
						}
					}else{
						_vm.users = response.data.data;
					}

				})
				.catch(function(error) {
				});
		},
		getAll() {
			let _vm = this;
			_vm.showLoader = true;
			this.axios
				.get("/clients-by-lead/" + this.$route.params.id)
				.then(function(response) {
					_vm.showLoader = false;
					_vm.clientList = response.data.data;
				})
				.catch(function(error) {
				});
		}
	},
	mounted() {
		this.getUser();
		this.getAll();
	}
};

</script>
