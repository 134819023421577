import { _ } from "vue-underscore";
import store from "@/store/index.js";
export default {
  data() {
    return {
      to: "",
      selectedId: 0,
      selectedLeadNumber: "",
      callRecordList: [],
      selectedleadid: [],
      client_id: "",
      showLoader: false,
      isButtonDisabled: false,
      rc_widget: localStorage.getItem("rc_widget") === "true" || false,
    };
  },
  props: {
    selectedLead: Object,
    // selectedClient: Object,
  },
  watch: {
    rc_widget(newValue, oldVal) {
      localStorage.setItem("rc_widget", newValue.toString());
    },
  },
  computed: {
    contactList() {
      if (
        this.selectedLead &&
        this.selectedLead.client &&
        this.selectedLead.client.client_phones
      ) {
        const phoneList = this.selectedLead.client.client_phones;
        const preferredContact = phoneList.find(
          (contact) => contact.preferred === 1
        );
        this.to = preferredContact
          ? preferredContact.prefix + preferredContact.value
          : "";
        return phoneList;
      }
      return [];
    },
  },
  methods: {
    maskContactValue(value) {
      if (value.length < 6) return value;
      const visibleChars = 2;
      const hiddenChars = value.length - visibleChars - 3;
      const maskedValue =
        value.substring(0, visibleChars) +
        "*".repeat(hiddenChars) +
        value.substring(value.length - 3);
      return maskedValue;
    },
    convertSecondsToMinutes(seconds) {
      const minutes = Math.floor(seconds / 60);
      const remainingSeconds = seconds % 60;
      return `${minutes} min ${remainingSeconds} sec`;
    },
    getClientCallHistory() {
      // const _vm = this;
      const clientId = this.selectedLead.client_id;
      const lead_id = this.selectedLead.id;

      this.axios
        .get(`/lead-call-logs?clientId=${clientId}&leadId=${lead_id}`)
        .then((response) => {
          let encryptedData = response.data.data;
          let decryptedData = atob(encryptedData);
          let data = JSON.parse(decryptedData);
          this.callRecordList = data;
          this.showLoader = false;
        })
        .catch((error) => {});
    },
    makePhoneCall() {
      const _vm = this;
      _vm.isButtonDisabled = true;
      store.state.isLoaderShow = true;
      _vm.axios
        .post("/ringout", { to: this.to })
        .then(function (response) {
          store.state.isLoaderShow = false;
          _vm.isButtonDisabled = false;
        })
        .catch(function () {
          store.state.isLoaderShow = false;
          _vm.isButtonDisabled = false;
        });
    },
    // getclientContact() {
    //   const _vm = this;
    //   _vm.axios
    //     .post("/ringout", { to: this.to })
    //     .then(function (response) {
    //       // _vm.contactList = response.data.data
    //     })
    //     .catch(function () {});
    // },
  },
  mounted() {
    const _vm = this;
    _vm.getClientCallHistory();
  },
};
