import { render, staticRenderFns } from "./lead-marketing-info.vue?vue&type=template&id=54a5392b&scoped=true"
import script from "./lead-marketing-info.js?vue&type=script&lang=js&external"
export * from "./lead-marketing-info.js?vue&type=script&lang=js&external"
import style0 from "./lead-marketing-info.vue?vue&type=style&index=0&id=54a5392b&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54a5392b",
  null
  
)

export default component.exports