<template>
  <div id="expertComment-modal">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content" style="background-color: white;">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel2">Expert Comments</h5>
                <button type="button" class="close" aria-label="Close" @click="$emit('close')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body">
                <div class="row">
                  <div>
                    <textarea type="text" id="Comment" style="border-radius: 8px;" name="description" rows="4"
                      placeholder="Enter Comment..." v-model="ExpertComment"
                      :disabled="!(isExpertSelfLead || isSelfLead && selectedLead.is_expert_asked == 1)"
                      class="au-input au-input--full form-control placeholderpadding"></textarea>
                    <div class="float-right">
                      <button type="button" class="btn btn-primary mt-1"
                        :disabled="!(isExpertSelfLead || isSelfLead && selectedLead.is_expert_asked == 1)"
                        @click="exertComments">Save</button>
                    </div>
                  </div>
                </div>
                <span style="font-weight: 700;">Comments :</span>
                <div class="row">
                  <div class="mt-1 border-top commentDivHeight">
                    <template v-for="commentData in selectedLead.lead_expert_feedback">
                      <span class="text-primary" style="font-weight: 700;">{{
                        commentData.user_name }}</span><span> - {{
                          commentData.created_at | formatDateShort }}</span>
                      <span class="d-block" style="text-wrap: wrap;">- {{ commentData.comment
                        }}</span>
                    </template>
                  </div>
                </div>
              </div>
              <!-- <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" data-dismiss="modal"
                        @click="$emit('close')">Close</button>
                    <button type="button" class="btn btn-primary">Save</button>
                </div> -->
            </div>
          </div>

        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vue from 'vue'
export default {
  name: 'expertComment-modal',
  data: () => ({
    ExpertComment: '',
    ExertCommentlist: [],
    userId: JSON.parse(atob(localStorage.getItem('user'))).id
  }),
  props: {
    selectedLead: Object,
    //   selectedClient: Object,
    getDetail: {
      type: Function,
      required: true,
    },
  },
  methods: {
    exertComments() {
      let _vm = this;
      const comment = _vm.ExpertComment;
      this.axios
        .post("/lead-expert-feedback", { lead_id: this.$store.state.leadId, comment })
        .then(function (response) {
          _vm.ExertCommentlist = response.data.data;
          _vm.ExpertComment = '';
          _vm.getDetail();
        })
        .catch(function (error) {

        });
    },
    // formatDateTime(timestamp) {
    //     const date = new Date(timestamp);
    //     const months = [
    //         "Jan", "Feb", "Mar", "Apr", "May", "Jun",
    //         "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"
    //     ];

    //     const month = months[date.getMonth()];
    //     const day = date.getDate().toString().padStart(2, '0');
    //     const year = date.getFullYear().toString().slice(2);
    //     const time = timestamp.slice(11, 16);

    //     return `${month}/${day}/${year} ${time}`;
    // },

  },
  computed: {
    isSelfLead() {
      return this.selectedLead?.assign_to == this.userId;
    },
    isExpertSelfLead() {
      return this.selectedLead?.expert_assign_to == this.userId;
    }
  },
  mounted() {

  }
}
</script>

<style scoped>
.modal {
  --bs-modal-margin: 0rem;
  --bs-modal-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
}

.modal-mask {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-dialog {
  top: 0;
  right: 0;
  height: 100vh;
  position: absolute;
  width: 35%;
  border-radius: 0;
}

.modal-content {
  border-radius: 0px;
}

.modal-body {
  min-height: 100vh;
  max-height: 100vh;
}

.commentDivHeight {
  min-height: calc(100vh - 190px);
  max-height: calc(100vh - 190px);
  overflow-x: hidden;
}

.form-dropdown {
  display: block;
  border-radius: 8px;
  height: 2em;
  font-size: 1rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
}

.modal-dialog-scrollable .modal-body {
  overflow-y: visible;
}

.pointer {
  cursor: pointer;
}

.xyz {
  background-color: blue;
}

.btnwidhei {
  width: 25px;
  height: 25px;
}

.dropdown-scroll {
  position: relative;
  z-index: 5000;
}

/* Popup form container */
.closeDiv {
  height: 20px;
}

.modal-dialog1 {
  width: 35%;
  border-radius: 0;
}

.modal-dialog1 .modal-body {
  min-height: auto;
  max-height: auto;
}

.modal-dialog1 .modal-content {
  border-radius: 0px;
  display: block;
  top: 100px;
  left: 80%;
  width: 135%;
}

.mx-datepicker-popup {
  z-index: 9999 !important;
}
</style>
