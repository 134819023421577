<template>
  <div id="lead-Pax">
    <transition name="modal">
      <div class="modal-mask">
        <div class="modal modal-wrapper">
          <div class="modal-dialog modal-xl" role="document">
            <div class="modal-content" style="background-color: white;">
              <div class="modal-header p-2">
                <h4 class="modal-title pl-2">Passenger's List</h4>
                <button type="button" class="close pr-4" @click="$emit('close')">
                  <span aria-hidden="true">&times;</span>
                </button>
              </div>
              <div class="modal-body bodyhei bgcolor">
                <b-row class="pt-3">
                  <b-col md="6" sm="6" lg="4">
                    <iq-card class="pt-2 pb-2" style="min-height: 23em; max-height: 23em; overflow-x: hidden;">
                      <template v-slot:body>
                        <div class="align-items-center justify-content-between">
                          <b-row>
                            <table class="col-md-12">
                              <tr v-for="(passenger, Index) in clientPassenger" :key="passenger.id">
                                <td style="width: 10%;">{{ Index + 1 }}.</td>
                                <td style="text-transform: uppercase;width: 70%;">{{ passenger.last_name }} / {{
                                  passenger.first_name }} {{
                                    passenger.middle_name }} </td>
                                <td style="width: 10%;"><i class="fa-solid fa-pen-to-square cpointer"
                                    @click="get(passenger.id)"></i></td>
                                <td style="width: 10%;">
                                  <template v-if="selectedPassenger === passenger.id">
                                    <i class="fa-solid fa-square-check  red-check cpointer"
                                      @click="deleteClientPassenger(passenger.id)"></i>
                                  </template>
                                  <template v-else>
                                    <i class="fa-solid fa-trash-can cpointer" @click="toggleRedDot(passenger.id)"></i>
                                  </template>
                                </td>
                              </tr>
                            </table>
                          </b-row>
                        </div>
                      </template>
                    </iq-card>
                  </b-col>
                  <b-col md="6" sm="6" lg="8">
                    <iq-card class="pt-2 pb-2" style="min-height: 23em; max-height: 23em; ">
                      <template v-slot:body>
                        <ValidationObserver ref="observer" v-slot="{ handleSubmit }">
                          <form @submit.prevent="handleSubmit(validatepax)">
                            <div class="align-items-center justify-content-between">
                              <b-row>
                                <div class="col-md-12">
                                  <div class="form-group">
                                    <p class="fontsize-sm m-0"><span class="text-danger">Notice:</span> Please refrain
                                      from disclosing any passenger list information unless the customer
                                      explicitly confirms that we have their consent to store this information in our
                                      system.</p>
                                  </div>
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="last_name" rules="required" v-slot="{ errors }">
                                    <label for="last_name" class="form-control-label">Last </label>
                                    <input type="text" id="last_name" name="last_name" v-model="pax.last_name"
                                      class="au-input au-input--full form-control inthei"
                                      :class="errors.length > 0 ? ' is-invalid' : ''">
                                  </validation-provider>
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="first_name" rules="required" v-slot="{ errors }">
                                    <label for="first_name" class="form-control-label">First</label>
                                    <input type="text" id="first_name" name="first_name" v-model="pax.first_name"
                                      class="au-input au-input--full form-control inthei"
                                      :class="errors.length > 0 ? ' is-invalid' : ''">
                                  </validation-provider>
                                </div>
                                <div class="col-md-4">
                                  <label for="middle_name" class="form-control-label">Middle</label>
                                  <input type="text" id="middle_name" name="middle_name" v-model="pax.middle_name"
                                    class="au-input au-input--full form-control inthei">
                                </div>
                              </b-row>
                              <b-row>
                                <div class="col-md-4">
                                  <!-- <validation-provider name="date" rules="required" v-slot="{ errors }"> -->
                                  <div class="form-group">
                                    <label for="exampleInputdate">Date of birth</label>
                                    <date-picker class=" mb-2" v-model="pax.date_of_birth" :editable="false"
                                      valueType="YYYY-MM-DD" :format="datePickerFormat"
                                      :disabled-date="isWithinNext14Months"></date-picker>
                                  </div>
                                  <!-- </validation-provider> -->
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="gender" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                      <label for="gender" class=" form-control-label">Gender</label>
                                      <select id="gender" class="au-input au-input--full form-control inthei"
                                        v-model="pax.gender" :class="errors.length > 0 ? ' is-invalid' : ''">
                                        <option value="" disabled>Please Select</option>
                                        <option value="Male">Male</option>
                                        <option value="Female">Female</option>
                                      </select>
                                    </div>
                                  </validation-provider>
                                </div>
                                <div class="col-md-4">
                                  <validation-provider name="type" rules="required" v-slot="{ errors }">
                                    <div class="form-group">
                                      <label for="type" class=" form-control-label">Type</label>
                                      <select id="type" class="au-input au-input--full form-control inthei"
                                        v-model="pax.type" :class="errors.length > 0 ? ' is-invalid' : ''">
                                        <option value="" disabled>Please Select</option>
                                        <option value="ADT">Adult</option>
                                        <option value="CHD">Child</option>
                                        <option value="INF">Infant</option>
                                      </select>
                                    </div>
                                  </validation-provider>
                                </div>
                              </b-row>
                              <b-row>
                                <div class="col-md-12">
                                  <div class="form-group float-right">
                                    <b-button :disabled="loading" type="submit" class="float-right ml-3"
                                      variant="success" id="submit">
                                      <template v-if="loading">
                                        <i></i> {{ pax.id == 0 ? "Add To List" :
                                          "Update To List" }}
                                      </template>
                                      <template v-else>
                                        <i></i>{{ pax.id == 0 ? "Add To List" :
                                          "Update To List" }}
                                      </template>
                                    </b-button>
                                    <!-- <button class="btn btn-success "  >Update to list</button> -->
                                  </div>
                                </div>
                              </b-row>
                            </div>
                          </form>
                        </ValidationObserver>
                      </template>
                    </iq-card>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>
import vue from 'vue'
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';
export default {
  name: 'lead-Pax',
  data: () => ({
    pax: {
      // client_id: selectedLead.client_id,
      id: 0,
      last_name: "",
      first_name: "",
      middle_name: "",
      date_of_birth: "",
      gender: ""
    },
    isDeleted: false,
    client_id: null,
    clientPassenger: null,
    selectedPassenger: null,
    loading: false,
    datePickerFormat: 'MMM/DD/YYYY',
  }),
  props: {
    selectedLead: Object,
    selectedClient: Object,
  },
  components: {
    DatePicker
  },
  computed: {

  },
  methods: {
    formatDate(date) {
      if (!date) return '';
      return new Date(date).toISOString().split('T')[0];
    },
    isWithinNext14Months(date) {
      const today = new Date(new Date().setHours(0, 0, 0, 0));
      return date > today
    },
    selectPassenger(passengerId) {
      this.selectedPassenger = passengerId;
    },
    resetForm() {
      let _vm = this;
      _vm.pax = {
        id: '',
        last_name: "",
        first_name: "",
        middle_name: "",
        date_of_birth: "",
        gender: ""
      };
      this.$refs.observer.reset();

    },
    toggleRedDot(passengerId) {
      this.selectedPassenger = passengerId;
    },
    // toggleRedDot() {
    //   this.isDeleted = true;
    // },
    validatepax() {
      this.loading = true;
      if (this.pax.date_of_birth) {
        this.pax.date_of_birth = this.convertDateToUTC(this.pax.date_of_birth);
      }
      setTimeout(() => {
        this.loading = false;
      }, 2000);
      this.pax.id > 0 ? this.update() : this.add();
    },
    update() {
      let _vm = this;
      const formattedDate = _vm.formatDate(_vm.pax.date_of_birth);
      _vm.pax.date_of_birth = formattedDate;
      _vm.axios
        .post("/client-passengers/" + _vm.pax.id, {
          ..._vm.pax,
          _method: "PATCH"
        })
        .then(function (response) {
          _vm.getClientPassenger();
          _vm.resetForm();
        })
        .catch(function () { });
    },

    add() {
      let _vm = this;
      const formattedDate = _vm.formatDate(_vm.pax.date_of_birth);
      _vm.pax.date_of_birth = formattedDate;
      const dataToSend = {
        ..._vm.pax,
        client_id: _vm.client_id
      };
      _vm.axios
        .post("/client-passengers", dataToSend)
        .then(function (response) {
          _vm.getClientPassenger();
          _vm.resetForm();
        })
        .catch(function () { });
    },
    deleteClientPassenger(id) {
      let _vm = this;
      _vm.axios
        .delete(`/client-passengers/` + id)
        .then(response => {
          _vm.getClientPassenger();
          this.selectedPassenger = null;
        })
        .catch(error => {

        });
    },
    convertDateToUTC(date) {
      if (!date) return '';  // Return empty if the date is not valid

      const utcDate = new Date(date);
      if (isNaN(utcDate.getTime())) {
        console.error('Invalid date provided for conversion.');
        return '';
      }

      return new Date(Date.UTC(utcDate.getFullYear(), utcDate.getMonth(), utcDate.getDate()));
    },
    get(id) {
      let _vm = this;
      _vm.axios
        .get(`/client-passengers/` + id)
        .then(response => {
          _vm.pax = response.data.data;
        })
        .catch(error => {

        });
    },
    getClientPassenger() {
      const selectedClientId = this.selectedLead.client_id;

      this.axios
        .get(`/client-passengers?client_id=${selectedClientId}`)
        .then(response => {
          this.clientPassenger = response.data.data;
        })
        .catch(error => {

        });
    },


  },
  created() {
    let _vm = this;
    _vm.client_id = _vm.selectedLead.client_id;

    _vm.getClientPassenger();
  },
}
</script>

<style>
.bgcolor {
  background-color: #d3d3d366;
  padding-top: 0px;
  padding-bottom: 0px;
}

.inthei {
  height: 33px;
}


.red-check {
  color: rgb(170, 61, 34);
  cursor: pointer;
}


.cpointer {
  cursor: pointer;
}

.mx-datepicker-popup {
  z-index: 9999
}

.mx-btn.mx-btn-text.mx-btn-icon-double-left {
  display: inline-block;
}

.mx-btn.mx-btn-text.mx-btn-icon-double-right {
  display: inline-block;
}

.mx-calendar.mx-calendar-panel-month .mx-calendar-header .mx-calendar-header-label .mx-btn.mx-btn-text {
  cursor: pointer;
  display: inline-block;
}
</style>
